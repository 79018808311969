body{
	.white-overlay,.white-overlay-filters {
		display: none;
		position: fixed;
		top: 0;
		bottom: 0;
        right:0px;
        left:0px;
		width: 100%;
		background: rgba(0,0,0,.3);
		z-index: 110;
		cursor: pointer;
	}
	.scroll-hidden{
		overflow: hidden;
	}
}
.bar-notification{
	display: none;
}

.body-bg-home{
	margin: 0px auto 0px;
	padding-bottom: rem-calc(20);
}
a{
	word-break: break-word;
}

/***headings****/
h1 {
  font-size: rem-calc(28);
  font-weight: 600;
}
h2{
	font-size: rem-calc(24);
	font-weight: 600;
}
h6{	
	font-weight: 600;
}

.breadcrumb{
	padding: rem-calc(15 0 15);
	font-size: rem-calc(14);	
	//margin-top: rem-calc(10);
	ul{
		display: inline;
		list-style: none;
		margin:0px;
		padding:0px;
		li{
			display: inline;
			
		}
	}
    .delimiter{
        margin:rem-calc(0 4);
        color:#bbbbbb;
    }
    strong{
        font-weight:500;
    }
}
.medium-gray{
	background: $medium-gray;
}

/*****Category page********/

.min-height-menu{
	@media only screen and (max-width: 1023px) { 
		min-height: 1px !important;
	}
}

.product-list__container{
	/*@include breakpoint(large){
		padding-left:rem-calc(80);
	}*/
	.product-list__header{
		padding:rem-calc(0 10);
	}
}
.category-list-page{
	&.page{
		margin-top: 0;
	}
}
/*.category-listing{
	@include breakpoint(large){
		padding-left:rem-calc(65);
		.category-page__header{
			padding-left:rem-calc(10);
		}
	}
}*/
/*.category-page{
	.breadcrumb{
		@include breakpoint(large){
			padding-left:rem-calc(75);
		}
	}
}*/

.category-page__grid{
	display: inline-block;
	width: 100%;
	padding-bottom: 30px;	
	.category-page__grid__item{
		margin-bottom: rem-calc(20);
		@include grid-column(12, 20);
		@include breakpoint(medium){
			@include grid-column(6, 15);
		}		
		@include breakpoint(large){
			@include grid-column(1 of 4, 20);
		}	
		&:last-child:not(:first-child){
			float:left;
		}
		.category-page__grid__link{
			border: 1px solid $medium-gray;
			display: block;
			text-align: center;
			color:$dark-gray;
			h2{
				font-size: rem-calc(15);
				font-weight: 400;
				padding: rem-calc(10 10 0);
				transition: all ease 0.1s;
				@include breakpoint(large){
					font-size: rem-calc(18);
				}
			}
			&:hover{
				h2{
					color: $primary-color;
				}
			}
		}
	}

}

/******GRIDs******/
.product-list__toolbar{	
	padding:rem-calc(0 10);
	.product-list__toolbar__action{
		padding: rem-calc(15 0 5);
		border-top:1px solid #8e8e8e;
		border-bottom:1px solid #8e8e8e;
		margin-bottom:20px;
		margin-top:rem-calc(20);
		@include breakpoint(large){
			margin-top:rem-calc(0);
		}
		.product-selectors{
			display: inline-block;
			width: 100%;
			.pager{
                padding:0px;
                border:none;
				@include breakpoint(large up){
					float: right;
				}
				& > span{
					font-size: 14px;
					margin-right: 10px;
					float: left;
					padding: rem-calc(5 0);
				}
			}
			.product-viewmode{
				display: none;
				@include breakpoint(medium){
					display: inline-block;
				}
				& > span{
					@include breakpoint(small only){
						display: none;
					}
				}
			}
			.product-sorting, 
			.product-page-size, 
			.product-viewmode{
				float: left;
				margin-right: 40px;
				font-size: 14px;
				@include breakpoint(medium down){
					margin-bottom: rem-calc(10);
				}	
				span{
					font-size: 14px;
					margin-right: 10px;
					float: left;
					padding: rem-calc(5 0);
				}				
			}
			select{
				/*width: auto;
				margin: 0px;
				border:1px solid $medium-gray;				
				font-size: 14px;
				padding: 0px 5px;
				border-radius: 0px;*/
                height: rem-calc(29);
				line-height: rem-calc(29);
			}
			.product-viewmode{
				a{
					float: left;
					display: block;
					font-size: 1.875rem;
					line-height: 1.8125rem;
					color: $darker-new-gray;
					margin-right: 0.625rem;
					&.selected {
						color:$primary-color;
					}
				}
			}			
		}
	}
	.product-list__toolbar__info {
		font-size:rem-calc(18);
		margin-bottom: 20px;
	}
}
.pager {
	float: right;
	ul{
		overflow: hidden;
		list-style-type: none;
		margin: 0;
		border: 1px solid $medium-gray;
		border-radius: 0px;
		display: inline-block;
		li {
			display: block;
			height: 28px;
			width: 28px;
			list-style: none;
			text-align: center;
			float: left;
			border-right: 1px solid $medium-gray;
			transition: all ease .1s;
			color: #000;
			position: relative;
			cursor: pointer;
			&:last-child{
				border-right:0px;
			}
			&:hover, &.current-page {
				background: $medium-gray;
			}
			&.next-page:before, &.previous-page:before, &.first-page:before, &.last-page:before{
				font-family: FontAwesome;
				line-height: 1.8;
				position: absolute;
				left: 0;
				right: 0;
			
			}
			&.previous-page, &.next-page{
				a{
					text-indent: -9999px;
					opacity: 0;
				}
			}
			&.previous-page:before {
				content: "\f104";				
			}
			&.next-page:before {
				content: "\f105";
			}
			span, a {
				display: block;
				font-size: 0.875rem;
				line-height: 1.875rem;
				color: #000;
			}
		}
	}
}
.nextopiaproductsearch {
    .category-page{
        .breadcrumb{		
            ul{
                li{
                    margin:0px;
                }
            }
	    }
        .product-list__description{
            padding:rem-calc(0 10);
        }
    }
}



	// @include grid-column(1 of 2);
	// 	@include breakpoint(medium){
	// 		@include grid-column(1 of 3);
	// 	}		
	// 	@include breakpoint(large){
	// 		@include grid-column(1 of 4);
	// 	}	

.product-list{
	display: inline-block;
	width: 100%;
	padding-bottom: 30px;	
	@include breakpoint(small only){
		.product-list__item{
			margin-bottom: rem-calc(20);
			border:0px;		
			.product-list__item__link{
				border: 1px solid $medium-gray;
				display: block;
				text-align: center;
				color:$dark-gray;
				padding:rem-calc(15);
				.product-list__item__picture-container{
					float: none;
					padding:rem-calc(10 0 15);
					img{
						max-height: rem-calc(100);
					}
				}
				.product-list__item__details {
					float: none;
					padding-left: 0px;
				}
				h2{
					font-size: rem-calc(16);
					font-weight: 400;
					padding: rem-calc(10 0 0);
					transition: all ease 0.1s;
				}
				&:hover{
					h2{
						color: $primary-color;
					}
				}
				.product-list__item__sku{
					font-size: rem-calc(14);
					color:#999999;
					word-wrap: break-word;
				}
				.product-list__item__prices{
					.product-list__item__price{
						font-size: rem-calc(14);
						display: block;
						&.product-list__item__price--old{
							text-decoration: line-through;
						}				
						.product-list__item__price__number{
							font-size: rem-calc(18);
							color:$primary-color;
							font-weight: 600;
						}
					}
				}
				.product-list__item__availability{
					font-size: rem-calc(14);
					padding: 5px;
				}
			}
		}
	}
	&.product-list--grid{
		.product-list__item{
			@include grid-column(12, 20);
			@include breakpoint(medium){
				@include grid-column(6, 15);
			}		
			@include breakpoint(large){
				@include grid-column(1 of 4, 20);
			}	
			&:last-child:not(:first-child){
				float:left;
			}
		}	
		.product-list__item{
			//margin-bottom: rem-calc(20);
			border:0px;		
			.product-list__item__link{
				border: 1px solid #8e8e8e;
				display: block;
				text-align: center;
				color:$dark-gray;
				padding:rem-calc(15);
				.product-list__item__picture-container{
					float: none;
					margin:rem-calc(10 0 15);
                    height:rem-calc(100);
					img{
						max-height: rem-calc(100);
                        font-size:rem-calc(12);
					}
				}
				.product-list__item__details {
					float: none;
					padding-left: 0px;
				}
				h2{
					font-size: rem-calc(16);
					font-weight: 400;
					padding: rem-calc(10 0 0);
					transition: all ease 0.1s;
				}
				&:hover{
					h2{
						color: $primary-color;
					}
				}
				.product-list__item__sku{
					font-size: rem-calc(14);
					color:#999999;
					word-wrap: break-word;
				}
				.product-list__item__prices{
					.product-list__item__price{
						font-size: rem-calc(14);
						display: block;
						&.product-list__item__price--old{
							text-decoration: line-through;
						}				
						.product-list__item__price__number{
							font-size: rem-calc(18);
							color:$primary-color;
							font-weight: 600;
						}
					}
				}
				.product-list__item__availability{
					font-size: rem-calc(14);
					padding: 5px;
				}
			}
		}
	}
	&:not(.product-list--grid){
		padding: rem-calc(0 10);
		.product-list__item__picture-container{
			@include breakpoint(small only){
				text-align: center;
				width: 100%;
				padding:rem-calc(15 0);
			}
			@include breakpoint(medium up){
				padding:rem-calc(15 25);
			}
		}
		.product-list__item__link{
			h2{
				&.product-list__item__title{
					font-size: rem-calc(20);
				}
				transition:  all linear 0.1s;
			}
			&:hover{
					h2{
						color: $primary-color;
					}
				}
			@include grid-row;
			@include breakpoint(medium up){
				.product-list__item__picture-container{
					@include grid-column(2, 15);
				}
				.product-list__item__details{
					@include grid-column(10, 5);
				}
			}
		}
		.product-list__item__price__number{
			font-weight: 600;
		}
		.product-list__item__details{
			padding:rem-calc(15 0);
			@include breakpoint(medium up){
				padding:rem-calc(15 0 0 10);
			}
		}
	}
	@include breakpoint(medium){
		.product-list__item{
			//margin-bottom: rem-calc(20);		
			border-bottom: 1px solid #8e8e8e;
			padding:10px 0px;
			.product-list__item__link{			
				display: inline-block;
				width: 100%;
				text-align: left;
				color:$dark-gray;
				.product-list__item__picture-container{
					float: left;
				}
				.product-list__item__details {
					float: left;
					padding-left: 10px;
				}
				h2{
					font-size: rem-calc(16);
					font-weight: 400;
					@include breakpoint(small only){
						height: auto !important;
					}
					@include breakpoint(large){
						//font-size: rem-calc(16);
					}
				}
				.product-list__item__sku{
					font-size: rem-calc(14);
					color:#999999;
				}
				.product-list__item__prices{
					.product-list__item__price{
						font-size: rem-calc(14);
						display: block;
						&.product-list__item__price--old{
							text-decoration: line-through;
						}				
						.product-list__item__price__number{
							font-size: rem-calc(18);
							color:$primary-color;
						}
					}
				}
				.product-list__item__availability{
					font-size: rem-calc(14);				
				}
			}
		}
	}
}
.page{
	//margin-top:rem-calc(30);
	margin-bottom:rem-calc(30);
	@include breakpoint(medium down){
		//margin-top:rem-calc(20);
		margin-bottom:rem-calc(20);
	}
	&.registration-result-page{
		@include breakpoint(large up){
			padding:rem-calc(30 0);
		}
	}
    &.topic-page{        
		padding:rem-calc(10 20);
        background:$white;		
    }
}
.topic-page-container{        
	padding:rem-calc(10 20);
    background:$white;		
}
.grey-bg-inner{
    margin:0px auto;
    max-width:rem-calc(1170);
}
.margin-top20{
    margin-top:rem-calc(20);
}
.entity{
    .entity-title{
        h2{
            a{
                color:$body-text;
            }
        }
    }
}
/*Livechat*/
div#lhnHocButton div.lhnHocChatBtnCont {
    background-color: $primary-color;
}
div#lhnHocButton.lhnround div.lhnHocChatBtnCont {
    background-color: transparent;
}
div#lhnHocButton div.lhnHocChatBtn {
    background-color: $primary-color;
}

div#lhnHocButton div.lhnHocChatBtn_border {
    border: 4px solid #1997AF;
}

div#lhnHocButton div.lhnHocChatBtnShadow {
    box-shadow: -2px 3px 8px 1px #A0A0A0;
}

div#lhnHocButton div.lhnHocFrontBubble {
    background-color: #ffffff;
}

div#lhnHocButton div.lhnHocFrontBubble:before {
    border-color: #f0f1f1 transparent transparent #f0f1f1;
}

div#lhnHocButton.lhnround div.lhnHocFrontBubble:after {
    border-color: transparent #f0f1f1 #f0f1f1 transparent;
}
div#lhnHocButton div.lhnHocChatTalk {
    background-color: $primary-color;
}

div#lhnHocButton div.lhnHocBackBubble {
    background-color: #6fdaf1;
}

div#lhnHocButton div.lhnHocBackBubble:after{
    border-color: #6fdaf1 #6fdaf1 transparent transparent;
}

div#lhnHocInvite div.lhnHocInviteCont{
  background-color: $primary-color;
  color: #FFFFFF;
}
div#lhnHocInvite div.lhnHocInviteButton{
  border: 1px solid #ffffff;
  background-color: $primary-color;
}
div#lhnHocInvite div.lhnHocInviteButton.lhnstart{
  background-color: #ffffff;
  color: $primary-color;
}

#lhnHelpOutCenter div.lhnRadioGroup input:checked + span {
  background-color: $primary-color;
  color: white;
}

/* line 112, ../scss/_forms.scss */
#lhnHelpOutCenter button.lhnFormButton,
#lhnHelpOutCenter button.form_submit {
  background-color: $primary-color;
  color: white;
}
#lhnHelpOutCenter button.lhnFormButton:hover,
#lhnHelpOutCenter button.form_submit:hover {
  background-color: #078bdc;
}

/* line 2, ../scss/_window-header.scss */
#lhnHelpOutCenter div.lhnWindow-header {
  color: white;
  background-color: $primary-color;
}

/* line 42, ../scss/_window-message.scss */
#lhnHelpOutCenter div.lhnCustomerMessage div.lhnWindow-message {
  background-color: $primary-color;
  color: white;
}
/* line 47, ../scss/_window-message.scss */
#lhnHelpOutCenter div.lhnCustomerMessage div.lhnWindow-message:after {
  border-color: transparent transparent transparent $primary-color;
}
/* line 44, ../scss/_window-chat_input.scss */
#lhnHelpOutCenter div.lhnWindow-chat_input button {
  background-color: $primary-color;
  color: white;
}
#lhnHelpOutCenter div.lhnWindow-chat_input button:hover {
  background-color: #078bdc;
}

#lhnHelpOutCenter div.lhnPrompt button.lhnFormButton{
  border: 2px solid $primary-color;
}
#lhnHelpOutCenter div.lhnPrompt button.lhnFormButton.lhnPromptDecline{
  background-color: #ffffff;
  color: $primary-color;
}
#lhnHelpOutCenter div.lhnChatActionsMenu img{
  background-color: $primary-color;
}
#lhnHelpOutCenter div.lhnWindow-footer a {
  text-decoration: none;
  color: $primary-color;
}
#lhnHelpOutCenter div.lhnWindow-body .lhnLabel span.lhnRequired{
    color:$alert-color;
}
/**/
.flex-row{
	display: flex;
	flex-wrap: wrap;
}