.fancybox-skin{
	background:$white;
}
.email-a-friend-page {
    &.page{
        margin-top: 0;
    }
    .result{
        padding:rem-calc(20 10);
    }
	h1{
		padding:rem-calc(5 15);
		background: $primary-color;
		color:$white;
		margin:0;
		border-radius: rem-calc(5);
	}
    .table-wrapper {
        .product {
            width: 80%;
        }
    }
    .buttons {
        text-align: right;
    }
    .email-cart-form-row {
        @include grid-row;
        .form-fields {
            border:none;
            @media all and (min-width: 768px) {
                .inputs {
                    &:after {
                        content: '';
                        display: table;
                        width: 100%;
                    }
                    label {
                        // float: left;
                        width: 40%;
                        //padding-right: rem-calc(10);
                        text-align: right;
                    }
                    input[type="text"],
                    select {
                        float: right;
                        width: 56%;
                    }
                    .field-validation-error {
                        display: block;
                        margin-bottom: rem-calc(3);
                        // float: left;
                        // width: 100%;
                        float: right;
                        width: 56%;
                        span {
                            text-align: left;
                            width: 56%;
                            display: block;
                            float: right;
                        }                       
                    }
                     &.email-field-error{
                         .field-validation-error {
                            span{
                                width:auto;
                                float:none;
                            }
                        }
                    }
                }
            }
        }
        .ycd-column,.rcd-column {
            padding:0;
            border:0;
        	@include breakpoint(medium up){
				@include grid-column(6);
				h4{
					text-align: center;
				}
        	}
        }
        &.send-rfq{
        	.form-fields{
        		border-bottom:none;
        		padding-bottom: 0;
        	}
        }
    }
}
