// partial for all nop related styling
$product-detail-page-breakpoint: 800px;
.product-selectors {
	select {
		width: auto;
		padding-top: 0;
    padding-bottom: 0;
    line-height: rem-calc(25px);
    height: rem-calc(25px);
    border-radius: 3px;
    box-sizing: content-box;
    margin: 0;
		// margin: 0;
	}

	span,
	select {
		font-size: rem-calc(14);
	}

	.product-viewmode,
	.product-sorting,
	.product-page-size,
	.pager {
		float: left;
		margin-right: rem-calc(20);
		@include breakpoint(medium down){
			margin-bottom: rem-calc(10);
		}
		&:last-child {
			margin-right: 0;
		}
	}

	.product-viewmode {
		a {
			float: left;
			display: block;
			font-size: rem-calc(30);
			line-height: rem-calc(29);
			color: #8e8e8e;
			margin-right: rem-calc(10);

			&.selected {
				color: #444444;
				color: $primary-color;
			}
		}
		.shwresult-txt {
			display: inline-block;
		}
	}
	.product-sorting {
	}
	.product-page-size {

	}
}

.pager {

	ul {
		overflow: hidden;
		list-style-type: none;
    margin: 0;
    border: 1px solid #cacaca;
    //border-radius: 5px;
    display: inline-block;

    .blog-page & {
    	display: inline-block;
    }

		li {
			display: block;
			height: rem-calc(30);
			width: rem-calc(30);
			list-style: none;
			text-align: center;
			float: left;
			border-right: 1px solid #cacaca;
			transition: all ease .1s;
			color: #000;

			&:last-child {
				border-right: 0;
			}
			&:hover,
			&.current-page {
				background: #eee;
			}

			&.current-page {
				color: $primary-color;
			}

			&.next-page,
			&.previous-page,
			&.first-page,
			&.last-page {
				position: relative;

		    &:before {
					font-family: FontAwesome;
					line-height: 1.8;
					position: absolute;
			    left: 0;
			    right: 0;
		    }

				a, span {
			    opacity: 0;
				}
		    // position: absolute;
		    // left: 0;
		    // right: 0;
			}

			a, span {
				display: block;
				font-size: rem-calc(14);
				line-height: rem-calc(30);
				color: #000;
			}

			&.next-page:before {
				content: "\f105";
			}
			&.previous-page:before {
				content: "\f104";
			}

			&.first-page:before {
				content: "\f100";
			}

			&.last-page:before {
		    content: "\f101";
			}
		}
	}
}

.product-filters {

}

.product-details-page {
	.product-tabs {
		.tabs-to-accordions {
		    border-radius: 0px;

		  @include breakpoint(medium) {
		    border-radius: 1px;
		  }
		}
		.accordion-title {
			&:hover,
			&:focus {
				color: #fff;
			}
		}
		.tabs-content {
			padding: rem-calc(25 30);
			margin: 0;
		}
		.tabs {
			border-bottom: 1px solid #fff;
		}
		.tabs-title {
			background-color: map-get($foundation-palette, 'primary');
			margin-left: rem-calc(2);
			transition:background-color 300ms ease;


			&:first-child {
				margin: 0;
			}

			a {
				transition:background-color 300ms ease;

				@include breakpoint(large) {
					font-size: rem-calc(16);
				}

				&:not([aria-selected="true"]) {
					&:hover,
					& {
						color: #fff;
					}
				}
			}

			&.is-active {
				a:hover,
				a {
					font-weight: bold;
					color: #000;
				}
			}
		}
	}
}

.admin-header-links { display: none; }
.bar-notification {
	display: none;
	&.success {
		display: block;
		padding: rem-calc(10 0);
		position: fixed;
		text-align: center;
		width: 100%;
		background: map-get($foundation-palette, 'primary');
		color: #fff;
	  min-height: rem-calc(45);
	  z-index: 9999;

		.close {
	    position: absolute;
	    right: rem-calc(30);
	    bottom: rem-calc(5);

		  &:hover {
		  	cursor: pointer;
		  }

		  &:after {
		    font-family: FontAwesome;
		    content: '\f2d3';
		    font-size: 24px;
		    pointer-events: none;
		  }
		}

		p {
			margin: 0;
			color: #fff;
		}

		a {
			color: #fff;
			text-decoration: underline;
		}
	}

}

.html-account-page {
	.page-title {
		text-align: center;
		font-size: rem-calc(24);
		margin: rem-calc(0 0 15px);

		@include breakpoint(small) {
			font-size: rem-calc(32);
		}
	}
}

.option-list {
	margin-left: rem-calc(10);
	margin-top: rem-calc(5);
	li {
		list-style: none;
	}
}
fieldset {
	span.required{
		color: map-get($foundation-palette, alert);
	}
}

form {
	.select-billing-address,
	.select-shipping-address {
		.checkout-page & {
			// margin-bottom: rem-calc(20);
		}
	}

	#PurchaseOrderNumber {
		margin: 0;
	}

	#payment-method-buttons-container {
		display: none;
	}

	.order-summary-content {
		table {
			th {
				background: map-get($foundation-palette, quarternary);
			}
		}
	}

	#divCustomShippingMethod {
		padding: rem-calc(0);

		select,
		input[type="text"] {
			@include breakpoint(medium) {
				width: auto;
			  display: inline-block;
		  	vertical-align: middle;
			}
		}

		input[type="input"] {
			max-width: rem-calc(386);
		}
	}

  ul.method-list + br {
  	display: none;
  }

	.inputs {
    position: relative;

    label {
    	display: inline-block;
    }

    span.required{
    	color: map-get($foundation-palette, alert);
    }
    span.field-validation-error{
    	color: map-get($foundation-palette, alert);
    	font-size: 14px;
			margin-bottom: 0;
    }
    select,
    textarea,
    input {
    	&.input-validation-error {
	    	border-color: map-get($foundation-palette, alert);
				margin: 0;
    	}
    }
	}

}

.recent-posts {
	margin: 0;

	li {
		list-style: none;
	}

	a {

	}
}

.listbox {
	overflow: hidden;
}

.validation-summary-errors{
	padding: rem-calc(10 5 0 15);
    font-size: 14px;
    color: map-get($foundation-palette, alert);
    ul{
    	margin-top: rem-calc(10);
    	margin-bottom: 0;
    }
}