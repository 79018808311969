@media print and (min-width:0) {

    body.page-cart {
        //font-family: Georgia, serif;
        background: none;
        color: black;
        font-size:rem-calc(14);
        h1{
            font-size:rem-calc(14);
        }
        .header__logo__image{
            width:rem-calc(150);
        }
        .header__main-menu,
        .header__search,
        .header__menu--mobile,
        .button,
        .delete-topic-button,
        .top-grey-row,
        .navigation-search-bar,
        .cart-top-links {
            display: none !important;
        }
        .header__phone__container{
            display: block !important;

            .header__phone{
                max-width: inherit;
            }
        }
        .customer-support-col{
            width:66%;
            padding-top:25px;
            .tel-number{
                margin: 0;
                display: block;
                text-align: right;
                width: 100%;
                span{
                    font-size: 14px;
                }
            }
        }
        a:after {
            content: none !important;
        }
        #printed-article {
            border: 1px solid #666;
            padding: 10px;
        }
        table {
            display: table !important;
            border-collapse:collapse !important;
            border-spacing: 0px !important;
            thead {
                display: table-header-group !important;
                th {
                    display: table-cell !important;
                }
            }
            tr {
                display: table-row !important;
                td {
                    display: table-cell !important;
                    font-size:rem-calc(14);
                    &.product-picture{
                        a{
                            display: inline-block !important;
                            height: 80px;
                            width: 80px;
                            img{
                                display: inline-block !important;
                            }
                        }                        
                    }
                    &.product{
                        label{
                            font-size:12px;
                        }
                    }
                    &.quantity{
                        text-align:center;
                        .qty-input{
                            display:inline-block;
                        }
                    }
                    &.unit-price{
                        text-align:right;
                    }
                    &.subtotal{
                        text-align:right;
                    }
                }
            }
        }
        .cart-total {
            display: inline-block !important;
        }
        .header__logo__link {
            img {
                max-width: 300px !important;
            }
        }
        .master-wrapper-page {
            .content__main {
                padding: 0;
            }
        }
        .order-summary-content {
            .table-wrapper {
                .cart {
                    a {
                        display: block;
                        text-decoration: none !important;
                    }
                }
                .mobile-shopping-cart{
                    display: none !important;
                }
            }
        }
        .blue-box-cart {
            border: none;
            .step-title {
                background: none;
                font-size:rem-calc(14);
            }
            .character-count {
                display: none;
            }
        }
        .footer{
        	display: none;
        }
    }
    #page {
        width: 100%;
        margin: 0;
        padding: 0;
        background: none;
    }
}
#pageFooter{
    display: none;
}
 .main-table-print{
  border:none;
  margin:0px;
  border-spacing: 0px;
  padding:0px;
}
table.main-table-print > thead {
    display: table-header-group !important;
    height: 0px;
    border:none;
    background: none;
}
table.main-table-print > thead td{
  font-size: 1px;
  padding: 0px;
}
table.main-table-print > tbody{
  border:none;
  background: none;
}
table.main-table-print > tbody > tr > td{
  border:none;
  padding: 0px;
}
table.main-table-print > tfoot{
    display: table-footer-group !important;
    border:none;
    background: none;
    padding: 0px;
}
table.main-table-print > tfoot td{
    padding: 0px;
}

body.page-cart #printableArea table tfoot{
  border:none;
  display: none;
}
   @media screen and (min-width:0) {
  table.main-table-print, table.main-table-print > thead, table.main-table-print > thead > tr, table.main-table-print > thead > tr > td, table.main-table-print > tbody, table.main-table-print > tbody > tr, table.main-table-print > tbody > tr > td, table.main-table-print > tfoot, table.main-table-print > tfoot > tr, table.main-table-print > tfoot > tr > td {
      display: block;
  }
}
@media print and (min-width:0) {
    #pageFooter{
        display: table-footer-group !important;
        position: running(pageFooter);
    }
    table.main-table-print > tfoot td{
        padding: 10px;
    }
      @page {
          size: auto;   /* auto is the initial value */
          /* margin:0px;   this affects the margin in the printer settings            */
          
      }
      
      
       /* #printableArea #pageFooter:last-child{
        display:none;
      }  */
}