// $block-product-slider: '.product-slider';
// #{$block-product-slider} {
// 	@include breakpoint(medium){
// 		padding-right: rem-calc(40);
// 		&.expertise-block{
// 			padding-top: rem-calc(20);
// 		}
// 	}	
// 	.prd-main-img{		
// 		// margin:rem-calc(0 15);
// 		text-align:center; 
// 		background: $white;
// 		box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.1); 
// 		position: relative;
// 		overflow:hidden;		
// 		@include breakpoint(medium){
// 			margin:rem-calc(10 0 0);
// 			width: 100%;
// 		}
// 		span.product-badge{
// 			position: absolute;
// 			left: rem-calc(10);
// 			top: rem-calc(10);
// 			z-index: 9991;
// 		}
// 		.zoomWrapper {
// 			img {
// 			  top: 0px;
// 			  bottom: 0px;
// 			  left: 0px;
// 			  right: 0px;
// 			  margin: auto;
// 			}
// 		}
// 	}
// 	.prd-variants{		
// 		padding:rem-calc(10 20);
// 		margin: auto;
// 		position: relative;
// 		z-index: 100;
// 		.variant-control{
// 			position: absolute;
// 			top: 0;
// 			bottom: 0;
// 			right: 0;
// 			left: 0;
// 			margin: auto;
// 			height: rem-calc(25);
// 			z-index: -1;
// 			#vnext{
// 				float: right;
// 			}
// 			.disabled{
// 				display: none;
// 			}
// 		}	
// 	}
// 	.prd-slide{		
// 		text-align: center;		
// 		padding: rem-calc(5);
// 		opacity: 0.5;
// 		max-width: rem-calc(165);
// 		img{
// 			max-width: 100%;
// 			max-height: rem-calc(120);	
// 			background: $white;
// 			box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.1); 
// 			// padding: rem-calc(10);
// 			border:2px solid transparent;
// 		}
// 		&.active{
// 			opacity: 1;
// 			img{
// 				border:2px solid $primary-color;
// 				box-shadow: none;
// 			}
// 		}
// 	}
// }
// .back-link{
// 	color: $secondary-color;
// 	font-size: rem-calc(11);	
// }
// .pro-detail{
// 	background: $white;	
// 	.content{
// 		padding: rem-calc(20 10);
// 		font-size: rem-calc(16);
// 		color: $black;
// 		p{
// 			font-size: rem-calc(16);
// 			color: $black;	
// 		}
// 		h2{
// 			display: none;
// 			color: $black;
// 			margin: rem-calc(10 0 30);
// 			@include breakpoint(medium){
// 				display: block;
// 			}
// 		}
// 		.button-block{
// 			text-align:center;
// 			@include breakpoint(medium){
// 				text-align: left;
// 			} 
// 		}
// 		.button{
// 			width: rem-calc(200);
// 			font-size: rem-calc(18);
// 			text-transform: uppercase;
// 			max-width: 100%;			
// 			margin-top: rem-calc(20);
// 			margin-right: rem-calc(20);			
// 		}		
// 	}
// 	@include breakpoint(medium){
// 		background: none;
// 		.content{
// 			padding:rem-calc(0 0 40 30);			
// 		}
// 	}
// }
// .product-slider-block{
// 	@include breakpoint(medium){
// 		h2,.button, a.back-link{
// 			display: none;
// 		}
// 	}
// 	h2{
// 		margin: rem-calc(15 0 25);
// 	}
// 	.button{
// 		width: rem-calc(300);
// 		font-size: rem-calc(18);
// 		text-transform: uppercase;
// 		max-width: 100%;
// 		margin-top:rem-calc(20); 
// 	}	
// }
// .stripe-block{
// 	background: url('../images/home-clients/clients-bg.png') repeat;
// 	padding:rem-calc(65 0);	
// 	border-top:1px solid #ccc;
// 	//margin-top: rem-calc(50);
// 	h3{
// 		text-align:center;
// 		font-size: rem-calc(20);
// 		font-weight: 900;
// 		margin-bottom: rem-calc(30);
// 		@include breakpoint(medium){
// 			text-align: left;
// 			font-size: rem-calc(24);
// 		} 
// 	}
// 	ul{
// 		margin-bottom: rem-calc(50);
// 		li{
// 			color: $black;			
// 		}
// 	}
// 	&.expertise-block{
// 		color: $black;
// 		p{
// 			color: $black;	
// 		}
// 		ul{		
// 			li{
// 				color: $black;			
// 			}
// 		}
// 	}	
// }
// .white-bg-block{
// 	padding: rem-calc(65 0);
// 	&.expertise-block{
// 		color: $black;
// 	}
// 	h3{
// 		text-align:center;
// 		font-size: rem-calc(20);
// 		font-weight: 900;
// 		margin-bottom: rem-calc(30);
// 		@include breakpoint(medium){
// 			text-align: left;
// 			font-size: rem-calc(24);
// 		} 
// 	}
// }
// .stripe-block, .white-bg-block{
// 	.spaced-columns{
// 		@include breakpoint(large){
// 			.col-space-left{
// 				padding-left: rem-calc(45);
// 			}
// 			.col-space-right{
// 				padding-right: rem-calc(45);
// 			}
// 		}	
// 	}
// }
// .common-learn-more{
// 	background-image: url('../images/want--more-call-bg.jpg');
// 	background-size: cover;
// 	background-position: center;
// 	background-repeat: no-repeat;
// 	padding: rem-calc(65 0);
// 	text-align: center;
// 	@include breakpoint(medium){
// 		padding: rem-calc(50 0);
// 	}
// 	h2{
// 		color: #333333;
// 		font-size: rem-calc(30);
// 		font-weight: 900;
// 		line-height: rem-calc(30);
// 		max-width: rem-calc(220);
// 		margin: 0px auto;
// 		text-transform: uppercase;
// 		@include breakpoint(medium){
// 			font-size: rem-calc(40);
// 			line-height: rem-calc(40);
// 			max-width: 100%;
// 		}
// 	}
// 	.call-loud{
// 		color:$secondary-color;
// 		font-size: rem-calc(20);
// 		font-weight: 700;
// 		margin: rem-calc(30 0 0 0);
// 		@include breakpoint(medium){
// 			font-size: rem-calc(24);
// 		}
// 		a{
// 			color:$secondary-color;
// 			margin-left: rem-calc(5);
// 		}
// 	}
// }
// .icon-block-content{
// 	text-align: center;
// 	padding-top: rem-calc(30);
// 	.icon-block{
// 		height: rem-calc(40);
// 		img{
// 			max-width: rem-calc(30);
// 		}
// 	}
// 	span.name{
// 		font-weight: 700;
// 		display: block;
// 		color: $black;
// 		margin:rem-calc(10 0 10 0);
// 	}
// }
// .features-pd{
// 	padding-top: rem-calc(20);
// 	.img-block{
// 		text-align: center;
// 	}
// 	@include breakpoint(medium){
// 		padding-top: 0px;
// 		.img-block{
// 			text-align: right;
// 		}
// 	}
// }
.page{
    &.product-details-page{
        margin-top: 0;
    }
}
.product-name{
    h1{
        margin-bottom: rem-calc(20);
        font-weight: 300;
        line-height:normal;
        font-size: 14pt;
    }
}
h2.heading {
    font-size: rem-calc(18);    
    background: $medium-gray;
    line-height: rem-calc(40);
    padding: 0px 15px;
    font-weight:500;
}

.description-block {
    .full-description {
        padding: 15px;
        word-wrap: break-word;
        h2 {
            font-size: rem-calc(16);
            font-weight: 600;
            color:$primary-color;
        }
    }
}

.product-essential {
    display: block;
    width: 100%;
    //padding-bottom: 20px;
    //text-align: center;
    .gallery {
        display: inline-block;
        //max-width: rem-calc(326);
        //width: 100%;
        position: relative;
        text-align: center;
        vertical-align: top;
        @include breakpoint(large){
            padding-left:0;
        }
        @include breakpoint(small only){
            margin-bottom: rem-calc(15);
        }
        >.picture {
            padding: 15px;
            width: 100%;
            box-sizing: border-box;
            border: 1px solid #dddddd;
            margin-bottom: 20px;
            a{
                display: inline-block;
            }
            // .zoomPad{
            //     float: none;
            // }
            .zoomWrapperImage img{
                max-width: inherit;
            }
        }
        >.picture-thumbs {
            display: flex;
            //flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: rem-calc(15);
            @include breakpoint(medium down){
                margin-bottom: rem-calc(10);
            }
            .thumb-container {
                //flex: 1;
                border: 1px solid #e6e6e6;
               // width: rem-calc(100);
                a {
                    display: inline-block;
                    img{
                        // max-width: rem-calc(100);
                        max-width: rem-calc(70);
                        vertical-align: top;
                        @include breakpoint(medium down){
                            max-width: rem-calc(75);
                        }
                    }
                    &.zoomThumbActive {
                        border: 1px solid #3d3d3d;
                    }
                    @include breakpoint(small only){
                        max-width: 86px;
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0px;
                    }
                }
            }
        }
    }
    .details-section{
            clear:both;
            margin-bottom:rem-calc(20);
            /*@include breakpoint(large){
                position:absolute;
                left: 0px;
                bottom:0;
            }*/
            .product-downloads{
                margin:0;
                padding:0;
                list-style-type:none;
                @include breakpoint(large up){
                    display: flex;
                }
                @include breakpoint(medium down){
                    display: flex;
                    flex-wrap: wrap;
                }
                li{
                    text-align: center;
                    display:grid;
                    line-height:rem-calc(15);
                    @include breakpoint(medium down){
                        margin:rem-calc(2);
                    }
                    a{
                        display: inline-block;
                        margin:rem-calc(0 10 15 0);
                        border: 1px solid #e6e6e6;
                        max-width: 86px;
                        padding:rem-calc(10 5);
                        @include breakpoint(medium down){
                            margin:rem-calc(10 10 0 0);
                        }
                        img{
                            max-width: rem-calc(50);
                            margin-bottom:rem-calc(7);
                        }
                        span{
                            display: block;
                            font-size: rem-calc(13);
                        }
                        &:hover{
                            span{
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
    .overview {
        text-align: left;
        vertical-align: top;
        position:relative;
        padding-bottom:rem-calc(111);
        @include breakpoint(medium down){
            padding-bottom: 0;
            margin-bottom: rem-calc(15);
        }
        @include breakpoint(large) {
            display: flex;
        }
        .product__ps__wrap{
            display: inline-block;
            vertical-align: top;
            width: 100%;
            .product__add-to-cart-wrap{
                display: inline-block;
                width: 100%;
                padding: rem-calc(15 0);
                @include breakpoint(large){
                    // display: flex;
                    // flex-wrap: wrap;
                    // max-width: 80%;
                    width:auto;
                    float:right;
                    padding: rem-calc(0);
                }
                .product__ps__avail-wrap{                    
                    vertical-align: top;
                    // display: inline-block;
                    float: left;
                    width: 220px;
                    margin-right: rem-calc(25);
                    @include breakpoint(small only){
                        margin-bottom: rem-calc(10);
                    }
                    @include breakpoint(large){
                        float: left;
                        width: 180px;
                    }
                    .product__ps__table__content{
                        padding:rem-calc(8 0);
                        table{
                            tbody{
                                border:0;
                                tr{
                                    td{
                                        padding:rem-calc(5 10);
                                        strong{
                                            font-weight: normal;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .cart-sku-wrap{                    
                    float: left;
                    width: rem-calc(240);                    
                    .box-title{
                        font-size: rem-calc(16);        
                        background: $medium-gray;
                        line-height: rem-calc(35);
                        text-align: center;
                        font-weight:500;
                    }
                    .box-border{
                        display: inline-block;
                        border: 1px solid #dddddd;
                        border-top: 0px;
                        width: 100%;
                        padding: rem-calc(8 20);
                    }
                    @include breakpoint(large){
                        float: right;
                        width: rem-calc(220);
                        margin-bottom: rem-calc(20);
                    }
                    /*@include breakpoint(xlarge){
                        width:rem-calc(350);
                    }*/
                }
                .sku{
                    vertical-align: top;
                    // display: inline-block;
                    @include breakpoint(large){
                        // max-width: rem-calc(200);
                        width: 100%;
                    }
                    .sku-title{
                        display: block;
                        font-size: rem-calc(14);
                        font-weight: 500;
                        padding: rem-calc(10 0);
                        text-align: right;
                        span{
                            position: relative;
                            top: 1px;
                        }
                    }
                    .sku-in{
                        font-size: rem-calc(18);
                        .sku-title{
                            display: block;
                            font-size: rem-calc(14);
                            font-weight: 600;
                            padding: rem-calc(10 0);
                            text-align: right;
                        }
                        .label-bold{
                            color:$primary-color;
                        }
                    }
                    .product__ps__header{
                        // margin-top: rem-calc(30);
                        // @include breakpoint(small only){
                        //     margin-top: rem-calc(15);
                        // }
                        span{
                            font-size: 14pt;    
                        }
                        i{
                            color: $success-color;
                        }
                    }
                }
                .add-to-cart{
                    // margin-top: rem-calc(30);
                    // @include breakpoint(small only){
                    //     margin-top: rem-calc(20);
                    // }
                    .add-to-cart-panel{
                        display:block;
                        margin: rem-calc(5 0 20 0);
                        /*display: flex;
                        align-items:center;
                        @include breakpoint(large){
                            display:block;
                        }*/
                        .quantity-container{
                            display: flex;
                            align-items:center;
                            justify-content: flex-end;
                            .qty-label{
                                margin-right: rem-calc(15);
                                font-size: rem-calc(14);
                                font-weight: 500;
                                color: #333333;
                                // @include breakpoint(small only){
                                //     margin-right: rem-calc(10);
                                // }
                            }
                            input[type="text"]{
                                border-radius:0;
                                max-width: rem-calc(70);
                                height: rem-calc(51);
                                // line-height: rem-calc(56);
                                text-align: left;
                                font-weight: bold;
                                color: #333;
                                // @include breakpoint(small only){
                                //     max-width: rem-calc(95);
                                // }
                            }
                        }
                        .button-container{
                            .button{
                                margin-bottom: 0;
                                /*margin-left: rem-calc(20);*/
                                text-transform: uppercase;
                                font-weight: 500;
                                font-family: $body-font-family;
                                width:100%;
                                font-size: rem-calc(14);
                                padding: 0;
                                margin-top:rem-calc(15);
                                margin-left:0px;
                                line-height: rem-calc(37);
                                height: rem-calc(36);
                                i{
                                    font-size: rem-calc(24);
                                    margin: rem-calc(0 6 0 0);
                                    position: relative;
                                    top: 3px;
                                }
                                /*@include breakpoint(large){
                                    width:rem-calc(200);
                                    margin-top:rem-calc(15);
                                    margin-left:0px;
                                }*/
                                /*@include breakpoint(xlarge){
                                    width:rem-calc(200);
                                }*/
                            }
                        }
                    }
                }
            }
        }
        .product-specs-box {
            padding: 0px;
            vertical-align: top;
            display: inline-block;
            margin-left: rem-calc(30);
            @include breakpoint(large){
                //width: 100%;
                //max-width: rem-calc(320);
                //float: right;
            }
            @include breakpoint(small only){
                margin-left: 0;
                margin-top: rem-calc(20);
            }
            table {
                width: auto;
                tbody {
                    tr {
                        &:nth-child(even) {
                            background: none;
                        }
                        td {
                            padding: 0px;
                            vertical-align: top;
                            &.spec-name{
                                white-space: nowrap;
                            }
                            &.spec-value {
                                font-weight: 600;
                                padding-left:rem-calc(10);
                                word-wrap: break-word;
                                //word-break: break-all;
                            }
                        }
                    }
                }
            }
        }        
    }
}

.label-bold {
    font-weight: 600;
}

.product-specs-box {
    padding:rem-calc(15);
    .item-box.swiper-slide {
        float: left;
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        @include breakpoint(medium) {
            width: 33%;
        }
        @include breakpoint(large) {
            width: 25%;
        }
        .product-item {
            border: 1px solid #ccc;
            text-align: center;
            font-size: 16px;
            color: #fff;
            .picture {
                padding: 10px;
            }
        }
        .product-item {
            .details {
                padding: 10px;
                background: #333;
                h2.product-title {
                    font-size: 16px;
                    a {
                        color: $white;
                    }
                }
            }
        }
    }
}
.prices{
    font-family: $body-font-family;
    text-transform: uppercase;
    margin-top: rem-calc(20);
    font-size: rem-calc(18);
    .product-price{
        font-weight: 600;
        color: $primary-color;
    }
}

.product__ps__table {
    display: inline-block;
    width: 100%;
    .product__ps__table__content {
        display: inline-block;
        border: 1px solid #dddddd;
        border-top: 0px;
        width: 100%;
        table {
            margin-bottom: 0px;
            border: 0px;
            text-align: center;
            border-collapse: collapse;
            tbody {
                tr {
                    &:nth-child(even) {
                        background: none;
                    }
                }
            }
        }
    }
    div.title {
        font-size: rem-calc(16);        
        background: $medium-gray;
        line-height: rem-calc(35);
        text-align: center;
        font-weight:500;
    }
}

.section-wrap{
    &.description-block{
        margin-top: rem-calc(10);
    }
}
.short-description{
    .sku-in{
        color: $primary-color;
        font-size: 16pt;
        margin-bottom: rem-calc(5);
    }
    table{
        tbody{
            background: none;
            tr{                
                background: none;
                @include breakpoint(medium){
                    display: inline-block;
                    width: 50%;
                    vertical-align:top;
                }
                @include breakpoint(large){                    
                    width: 100%;
                }
                td{
                    padding: 0px;
                    font-size: rem-calc(15);                    
                    @include breakpoint(large){                    
                        display: block;
                    }
                    @include breakpoint(xlarge){
                        display: inherit;
                    }
                    span{
                        font-weight: 600;
                    }
                    &:first-child{
                       font-weight: 500;
                       padding-right:rem-calc(5);
                       color:$primary-color;
                   }
                }
            }
        }
    }
    h2{
        font-size: rem-calc(20);
    }
}
.discontinued-product-wrap {
    // margin-bottom: 20px;
    display: inline-block;
    .discontinued-title{
        font-size: rem-calc(14);
        color: #6b171a;
        font-weight: 600;
    }
    p{
        font-size: 8pt;
    }
    .button{
        margin-bottom: 0;
        /*margin-left: rem-calc(20);*/
        text-transform: uppercase;
        font-weight: 500;
        font-family: $body-font-family;
        width:100%;
        font-size: rem-calc(14);
        padding: 0;
        color: $white;
        margin-left:0px;
        line-height: rem-calc(37);
        height: rem-calc(36);
        i{
            font-size: rem-calc(24);
            margin: rem-calc(0 6 0 0);
            position: relative;
            top: 3px;
        }
        &:hover{
            color: $white;
        }
    }
    .replacedby-product {
        padding: rem-calc(10 15);
    }
}
.stock-unavailable{
    font-size: 8pt;
    margin-bottom: rem-calc(10);
}
// .product-gallery{
//     width:100%;
//     max-width: rem-calc(325);
// }
