﻿/*.filters-distributor {  
  background:$primary-color;
  padding:rem-calc(20 25);
  label{
      color:$white;
  }
  .button.secondary{
      margin-top:rem-calc(25);
  }
}

.distributor-block-grid{	    
    margin-bottom:rem-calc(10);
    .medium-gray{
        padding:rem-calc(25 15);        
    }
    h2{
        color:#002e60;
        font-size:rem-calc(20);
        font-weight:300;
        padding:rem-calc(0 15);
        text-transform:uppercase;
    }
	.distributor-block{
		margin-bottom: 20px;
		@include grid-column(1 of 1);
        @include breakpoint(medium){
			@include grid-column(1 of 2);
		}	
		&:last-child:not(:first-child){
			float:left;
		}
		.inner-box{			
			background: $white;
			padding: rem-calc(20);			
            border:1px solid $darker-new-gray;
            display:block;     
            word-break:break-all;
            h6{
                font-weight:500;
                margin-bottom:0px;
                font-size:rem-calc(18);
            }
            span{
                display:block;
                font-style:italic;
            }       
            a{
                display:block;
                font-size:rem-calc(15);
            }
		}		
	}
}*/
.filters-distributor{
    border-top:1px solid $left-nav-ul-border;
    border-bottom:1px solid $left-nav-ul-border;
    padding:rem-calc(30 0);
    .forms-fields{
        label{
            font-size:rem-calc(18);
            font-weight:600;
            margin-bottom:5px;
        }
        select{
            width:70%;
            float:left;
            height:rem-calc(50);
            margin: 0px;
            border:2px solid $left-nav-ul-border;
            border-radius:0px;
        }
        input[type=button]{
            width:25%;
            float: right;
            padding: rem-calc(18 10);
            text-align: center;            
            margin: 0px;
        }
    }
}
.distributor-block-grid{	    
    margin-bottom:rem-calc(10);
    margin-top:rem-calc(10);    
    h2{ 
        font-size:rem-calc(30);
        font-weight:600;
        padding:rem-calc(10 0);
    }
	.distributor-block{
		margin-bottom: 20px;
		.inner-box{			
			display:block;     
            word-break:break-all;
            h6{
                font-weight:600;
                margin-bottom:0px;
                font-size:rem-calc(20);
            }
            span{
                display:block;
                font-size:rem-calc(16);
            }       
            a{
                // display:block;
                font-size:rem-calc(16);
                word-break: break-all;
            }
		}		
	}
}