$block-hero: '.hero';

#{$block-hero}{
	position: relative;	
	overflow: hidden;
	max-height: rem-calc(420);
	@include breakpoint(large){
		max-height: rem-calc(470);
	}
	// max-height: rem-calc(470);
	// margin: rem-calc(0 -10);
	// @include breakpoint(medium){
	// 	margin: rem-calc(0 -15);
	// }
	// @include breakpoint(large){
	// 	margin: rem-calc(0);
	// }
	.cycle-slideshow{
		.slide{
			width: 100%;
			&.cycle-slide-active{
				visibility: visible !important;
			}			
			.slide-img-wrap{
				height: rem-calc(420);
				width: 100%;
				position: relative;
				background-position: center;
				background-repeat:no-repeat;
				text-align: center;
				background-size: cover;
				@include breakpoint(medium){
					height: rem-calc(470);
				}
				.slide-content{
					position: relative;
					text-align: left;
					.box-black {
						background: rgba(38,38,38,1);
						color: $white;
						position: absolute;
						top: 80px;
						left: 30px;
						padding: 20px;
						max-width: 290px;
						h2{
							font-weight: 300;
							span{
								font-size: rem-calc(18);
							}
						}
						@include breakpoint(small only){
							h2{
								font-size: rem-calc(22);
							}
						}
						&.box-fade-blue{
							top: 40px;
							left: 30px;
							right: 20px;
							max-width: 420px;
							padding: rem-calc(10 20);
							background: rgba(0,0,0,0.3);
							h2{
								margin-bottom: 0px;
							}
							@include breakpoint(medium){
								left: auto;
							}
						}
					}
					/***New design styles***/

					.box-black-new {
						background: rgba(0,0,0,0.4);
						color: $white;
						position: absolute;
						top: 20px;
						left: 30px;
						right: 30px;
						padding: 20px 25px;
						max-width: 590px;
						@include breakpoint(large){
							top: 40px;
						}
						@include breakpoint(xxlarge){
							left: 0px;
						}
						.button{
							padding: rem-calc(0 10);
							height: rem-calc(28);
							line-height: rem-calc(26);
							margin-bottom: 0px;
							color: $white;
						}
						h1{
							font-weight: 300;
							font-size: rem-calc(22);
							@include breakpoint(large){
								font-size: rem-calc(36);
							}	
						}
						
						.two-col{
							.image-thumb{
								display: none;
							}
							@include breakpoint(medium){
								display: flex;
								.content-banner{
									width:75%;
								}
								.image-thumb{
									display: block;
									width: 25%;
									text-align: right;
									align-self: flex-end;
								}
							}
						}				
					}
					/***end of New design styles***/

				}				
			}
		}
	}
	.slider-controls {
	  position: absolute;
	  width: 100%;
	//   background: #333;
	//   top: 36%;
	//   z-index: 110;
	  display: flex;
	  height: 100%;
	  justify-content: center;
		> span {
			position: absolute;
			display: block;
			background: rgba(0,0,0,0.3);
			z-index: 101;
			line-height: 100%;
			padding:rem-calc(5);
			font-size: rem-calc(20);
			top: 36%;
			@include breakpoint(large){
				padding: rem-calc(20 15);
				font-size: rem-calc(30);
			}
			cursor: pointer;
			color:$white; 
			&:hover{
				background: rgba(0,0,0,1);
			}
			&.prev{
				left: 0px;
			}
			&.next{
				right: 0px;
			}
			&.pause,&.resume{
				display: none;				
				margin: 0px auto;
				left: 48%;				
			}
		}
		.hero-pager {
			position: absolute;
			bottom: 0px;
			color: $white;
			z-index: 101;
			span {
				font-size: 40px;
				padding: 0px;
				margin: 0px 3px;
				line-height: normal;
				cursor: pointer;
				&:hover,&.cycle-pager-active{
					color:$secondary-color;
				}
			}
		}
	}	
	&:hover{
		.slider-controls{
			> span.pause, >span.resume{
				display: block;
			}
		}
	}
}