$block: '.block-account-navigation';
$nav-breakpoint: 920;

#{$block} {

	.listbox {
		@include breakpoint(small only) {
			overflow: visible;

			.menu { text-align: center; }
		}
	}
	.menu{
		border-bottom: 1px solid $medium-gray;
    	margin-bottom: .9375rem;
    	@include breakpoint(small only) {
		    margin-left: rem-calc(-11px);
  			margin-right: rem-calc(-11px);
  			font-size: 0;
    	}
    	&.simple{
    		li{
    			line-height: 1;
			    display: inline-block;
			    margin-right: 0;
    		}
    	}
    	li:list-child {
    		a {
    			@include breakpoint(small only) {
			    	border-right: 0;
			    }
    		}
    	}
    	a{
    		background: $medium-gray;
		    border-radius: 5px 5px 0 0;
	    	padding: rem-calc(12 3);
		    font-size: 12px;
            text-transform:capitalize;
            font-weight:500;
		    @include breakpoint(small only) {
		    	border-right: 1px solid #fff;
		    }
		    @include breakpoint(420) {
		    	padding: rem-calc(12 4);
		    	font-size: 13px;
		    }
		    &.active{
		    	background: $primary-color;
		    	color: $white;
		    }
		    @media screen and (min-width: 40em)	{
			    padding: .75rem;
			    font-size: 16px;
			}
  	}
	}
}
$block: '.account-page';

#{$block} {
	label, .gender{
		display: inline-block;
	}
	.gender{
		label{
			margin:0px;
		}
		input{
			position: relative;
		}
	}
	.inputs.date-of-birth{
		label{
			display: block;
		}
		select{
			width:auto;
			margin-right: 10px;
		}
	}
	input.check-box{
		position: relative;
	}
	.address-list,.order-list{
		@extend .clearfix;


		@include breakpoint(medium only) {
			@include grid-layout(2, '.grid-item', 10);
		}

		@include breakpoint(large) {
			@include grid-layout(3, '.grid-item', 15);
		}

		.grid-item {
			margin-bottom: rem-calc(10);
			@include breakpoint(medium only) {
				// margin-bottom: rem-calc(10);
			}
			@include breakpoint(large) {
				margin-bottom: rem-calc(15);
			}
		}

		.section.address-item, .section.order-item{
		  border: 1px solid $medium-gray;
		  padding: rem-calc(15);
		  height: 100%;
		  ul{
		  	margin:rem-calc(0 0 20 0);
		  	list-style: none;
		  	li{
		  		label{
		  			font-size: rem-calc(15);
		  		}
		  	}
		  }
		  .button{
		  	margin-bottom: 0px;
		  }
		  .address-buttons{
			  .button{
				margin-bottom: rem-calc(15);
			  }			  
		  }
		  .custom-attributes-view-bolded{
			  font-weight: 600;
		  }
		}
	}
}

.login-page,.password-recovery-page{
	.title, .topic-block-title {
	  font-size: rem-calc(16);
	  font-weight: 600;
	  color: #fff;
	  background: #333;
	  padding:rem-calc(5 15);
	}
	.forgot-password {
		float: right;
		a {
			font-size: rem-calc(14);
			color: map-get($foundation-palette, active);

			&:hover {
				text-decoration: underline;
			}
		}
	}
	.customer-blocks{
	    width: 100%;
		border:none;
		.login-wrapper {
		  float: left;
		  padding: 0px;
		  display: inline-block;
		  vertical-align: text-top;
		  text-align: left;
		  width: 100%;
		  margin-bottom: rem-calc(20);
		  border: 1px solid #333;
		  position: relative;
		  padding-bottom: rem-calc(70);
			.text{
				padding: rem-calc(15);
			}
			.form-fields{
				padding: rem-calc(15 15 0 15);
				.inputs.reversed{
					input[type=checkbox]{
						position: relative;
						top: rem-calc(2);
						margin-right: rem-calc(3);
					}
					label{
						display: inline-block;
					}
				}
			}
			.buttons{
				padding: rem-calc(0 15 15 15);
				position: absolute;
				right: 0px;
				bottom: 0px;
				.button{
					margin-bottom: 0px;
					margin-top: 10px;
					width: 100%;
					@include breakpoint(small){
						width: auto;
					}
				}
			}
		}
		@include breakpoint($nav-breakpoint) {
			display: inline-block;
	    	border: 1px solid #333;
	    	margin-bottom: 20px;
			.login-wrapper {
				width: 50%;
				margin: 0px;
				border:none;
				border-right: 1px solid #949494;
				&.register-block{
					border-right: none;
				}
			}
		}
		@include breakpoint(large) {
			width: 80%;
		}
		&.registration-benefits{			
			border: none;
			@include breakpoint(large) {
				width: 60%;
			}	
			h5{
				margin-bottom: rem-calc(20);
			}
			ul{
				text-align: left;
				display: inline-block;
				margin-bottom: 0px;
				width:80%; 
				@include breakpoint(large) {
					width:90%; 
				}
			}

		}
	}
	.topic-block{
		display: inline-block;
	    border: 1px solid #333;
	    width: 100%;
	    @include breakpoint(large) {
	    	width: 80%;
	    }
	    .topic-block-title{
	    	h2{
	    		font-size: rem-calc(16);
	    		margin: 0px;
	    		font-weight: bold;
	    	}
	    }
	    .topic-block-body{
	    	padding: rem-calc(15 15 0 15);
	    }
	}
	.checkout-as-guest-or-register-block{
		.topic-block{
			width: 100%;
			border:none;
		}
	}
}
.password-recovery-page{
	border: 1px solid #333;
	margin: 30px auto;
	@include breakpoint(medium) {
    	width: 40%;
    }
    .page-body{
    	.result{
    		color: green;
    		&.failure{
    			color: #cc4b37;
    		}
    	}
    	padding: rem-calc(15);
    	.fieldset{
    		margin:0px;
    		border:none;
    		padding: 0px;
    	}
    	.buttons{
    		.button{
    			margin-bottom: 0px;
    		}
    	}
    }
}
.registration-page{
	.page-body{
		.fieldset {
			padding:0;
			border:0;
			@include breakpoint(medium down){
				margin-top: 0;
			}
		  .title{
		  		@include breakpoint(medium down){
					padding: rem-calc(0);
					margin-bottom: 0;
		  		}
			}
			.form-fields{
				padding: rem-calc(0 15);
				border:none;
				margin-bottom: 0;	
				@include breakpoint(medium){
					border:none;
					margin-bottom: 0;
				}
				@include breakpoint(small only){
					padding:rem-calc(5 0 0 0);
				}
				.gender{
					display: inline-block;
					input[type=radio]{
						position: relative;
					}
					label{
						margin:0px;
					}
				}
				label{
					display: inline-block;

				}
				.date-of-birth label{
					display: block;
				}
				select{
					width: 25%;
					margin-right:10px;
				}
				.inputs.country{
					select{
						width: 100%;
					}
				}
				.newsletter-block{
					display: inline-block;
					margin-right: rem-calc(10);
					font-size: rem-calc(14);
					input{
						position: relative;
						left: 2px;
						top: 2px;
					}
				}
			}
			&.contact-info{
				@include breakpoint(medium){
					margin-top: 0px;
					.form-fields{
						padding-top: rem-calc(3);
					}
				}
			}
			&.company-details{
				@include breakpoint(medium){
					margin-bottom: rem-calc(10);					
				}
			}

		}
	}
}

.edit-address {
	margin: 0 auto;	
	max-width: rem-calc(464);
	.existing-shipping-address &, .existing-billing-address &{
		@include breakpoint(medium down){
			max-width: 100%;
		}
	}
}
#checkout-shipping-load{
	.select-shipping-address{
		margin-bottom: rem-calc(20);
	}
}
#billingSuccessMessage, #shippingSuccessMessage{
	padding: rem-calc(15);
}
.communication-pre-wrap{
	margin:0;
	.form-fields{
		padding-bottom:0;
		.custom-attributes{
			padding-bottom:0;
			select{
				@include breakpoint(medium up){
					margin-bottom: 0;
				}
				@include breakpoint(small only){
					margin-right: 0 !important;
					width: 100% !important;
				}
			}
		}
	}
}
.custom-attributes{
	.option-list{
		list-style-type: none;
		margin:rem-calc(0 0 15 0);
	}
}
@include breakpoint(medium) {
	body.customer {
		h1  {
			display: inline-block;
		}
		.custom-attributes {
			.option-list {
				li {
					width: 51%;
					max-width: rem-calc(300);
					float: left;
					box-sizing: border-box;
					padding-right: 2%;

					&:nth-child(even) {
						padding: 0;
						width: 49%;
					}
				}
			}
		}
	}
	.pdf-invoice-button, .print-order-button {
		float: right;
		margin-right: rem-calc(20);
	}
}

.order-details-page {
	.shipping-info,
	.billing-info {
		margin-bottom: rem-calc(10);
	}

	.page__header {
		@include breakpoint(medium) {
			display: inline-block;
		}
	}

	.order-overview,
	.order-details-area,
	.shipping-info-wrap,
	.section.shipments {
		margin-bottom: rem-calc(15);
		padding-bottom: rem-calc(15);
		border-bottom: 1px solid map-get($foundation-palette, divider);

		@include breakpoint(medium) {
			border-bottom: 0;
			padding-bottom: 0;
			margin-bottom: rem-calc(20);
		}
	}

	.section.shipments {
		padding: 0;
	}

	.sku-number,
	.shipment-id {
		white-space: nowrap;
	}

	ul {
		list-style: none;
		margin: 0;

		.title {
			font-weight: bold;
		}

		.label {
			padding: 0;
			color: #000;
			background: transparent;
			font-size: rem-calc(16);
		}
	}
	.section.totals table.cart-total tr td {
		padding: rem-calc(5);
	}

	@include breakpoint(small down) {
		.data-table {
			margin: 0;
		}

		table.cart-total tr td.cart-total-left label {
			font-size: rem-calc(16);
		}
		.table-wrapper {
			td {
				padding: 0;
				display: block;
				margin-bottom: 6px;
			}

			tbody {
				tr {
					width: 100%;
			    display: list-item;
			    list-style-type: disc;
			    margin-left: rem-calc(18);
					font-size: rem-calc(15);
				}
			}

			label {
				font-size: rem-calc(15);
				display: inline;
			}
		}
	}
}

#orders-grid {
	.button{
		text-transform: uppercase;
	}
	@include breakpoint(small down) {
		th,td {
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(5) {
				display: none;
			}
		}

	}

	@include breakpoint(small only) {
		font-size: rem-calc(13);
		th{
			padding-left:rem-calc(10);
			padding-right:rem-calc(10);
		}
		.button{
			padding:rem-calc(4);
		}
	}
}



.sperson {
	&-row {
		@extend .clearfix;
	}
	&-image {
		float: left;
		margin-right: 15px;
		border-radius: 500px;
		box-shadow: 0 5px 5px rgba(0, 0, 0, 0.34), inset 0 0 15px rgba(0, 0, 0, 0.3);
		overflow: hidden;

		@include breakpoint(small only) {
			display: inline-block;
			float: none;
		}
	}

	&-name {
		font-weight: 500;
		font-size: rem-calc(16);
	}

	&-info {
		padding-left: rem-calc(105);

		@include breakpoint(small only) {
			padding: 0;
		}
	}

	&-contact {
		&-header {
			font-size: 14px;
			font-weight: bold;

		}
	}
}

.k-loading-mask {
	display: none !important;
}
.k-pager-info {
	display: block;
	margin-bottom: rem-calc(10);
	font-size: rem-calc(14);

	@include breakpoint(medium) {
		font-size: rem-calc(16);
	}

}
.order-table {
	margin: 0;

	.k-pager-wrap {
		overflow: hidden;
		list-style-type: none;
		margin: 0;
		display: inline-block;
		border: 1px solid #cacaca;
		border-radius: 5px;
		ul {
			display: inline-block;
			margin: 0;
			float: left;
			li {
				display: block;
		    height: 1.875rem;
		    width: 1.875rem;
		    list-style: none;
		    text-align: center;
		    float: left;
		    border-right: 1px solid #cacaca;
		    transition: all ease .1s;
			}
		}

		& > a{
			display: block;
	    height: 1.875rem;
	    width: 1.875rem;
	    list-style: none;
	    text-align: center;
	    float: left;
	    border-right: 1px solid #cacaca;
	    transition: all ease .1s;
	    position: relative;
		}

		li a,
		li span,
		a span {
			display: block;
			font-size: 0.875rem;
			line-height: 1.875rem;
			color: #000;
		}

		.k-state-selected,
		a:hover {
	    background: #eee;
		}

		.k-pager-nav {
			span {
	    	opacity: 0;
	    	font-size: 0;
			}

			&:before {
				content: "\f104";
				font-family: FontAwesome;
				line-height: 1.75rem;
				position: absolute;
				left: 0;
				right: 0;
			}

			&.k-pager-last {
		    &:before {
		    	content: "\f101" !important;
		    }
			}

			&.k-pager-first {
		    &:before {
		    	content: "\f100" !important;
		    }
			}
		}

		.k-pager-numbers + .k-pager-nav {
			&:before {
				content: "\f105";
			}
		}

		.k-pager-refresh,
		// .k-state-disabled,
		// .k-pager-info,
		.k-pager-sizes {
			display: none;
		}
	}

	table {
    border-collapse: collapse;
	}

	thead {
		background: $primary-color;
	}

	th {
		padding: 0.375rem 0.75rem;
		font-weight: 500;
		color: #fff;
	}

	.button {
		margin: 0;
		@include breakpoint(small down) {
  		padding: 0 4px;
	    font-size: 12px;
	    display: block;
			white-space: nowrap;
		}
	}
}

.change-password-page{
	.text-box {
		width: 45%;
	}

	.fieldset{
		margin: 0;

		.form-fields{
			padding: rem-calc(15 15 0 15);
			border-bottom: none;
		}

		.buttons{
			padding-top: rem-calc(5);
		}
	}
}

.address-edit-page{
	.edit-address{
		.inline-label{
			padding-bottom:rem-calc(10);
			select{
				margin-bottom:0;
			}
		}
	}
}
.block-account-navigation{
	.menu{
		@include breakpoint(small only){
			margin:rem-calc(0 0 15 0);
		}
	}
}
.change-password-page{
	.chng-pass-admin{
		border:0;
		padding:0;
		.form-fields{
			padding:rem-calc(10 0 0 0);
			@include breakpoint(small only){
				.text-box{
					width: 100%;
				}
			}
		}
	}
}
input:disabled, input[readonly], textarea:disabled, textarea[readonly]{
    background:$medium-gray;
}