
.nexto-page{
	min-height:rem-calc(500);
	.product-list__container{
		padding: 0;
	}
}
.nextopiaproductsearch{
	.row{
        @include breakpoint(small only){
		    &.category-page{
			    margin:0;
		    }
        }
	}
	.breadcrumb{		
		@include breakpoint(medium up){
			padding-left:rem-calc(10);
		}
        ul{
            li{
                margin:rem-calc(0 3);
                //color:#bbbbbb;
                &:first-child{
                    margin-left:0px;
                }
            }
        }
	}
	.product-list{
		&.product-list--grid{
			.product-list__item__link{
				position: relative;
				.product-list__item__details{
					padding-bottom:rem-calc(45);
					.prdlist-detail-wrap{
						position:absolute;
						text-align: center;
						bottom:0;
						left: 0;
						width: 100%;
						padding-bottom:rem-calc(10);
						.product-list__item__prices{
							font-weight: 600;
						}
					}
				}
			}
		}
	}
	
	
}
.ui-widget{
	max-width:rem-calc(775);
	@include breakpoint(small only){
		max-width: 95%;
	}
		&.ui-autocomplete{
			background: $dark-gray;
			color: $white;
			z-index: 999;
			border:0;
			li{
				font-size: rem-calc(14);
				border:0;
				a{
					color: $white;
					padding:rem-calc(5 10);
					border:0;
					font-family: $body-font-family;
					&.ui-state-active{
						padding:rem-calc(5 10);
						color: $white;
						background:$primary-color;
						border:0;
						margin:0;
					}
				}
			}
		}
} 
#displayOptions,
#nsearchError{
	.didyoumean{
		margin:rem-calc(15 0);
		.stress{
			.serchnxtBold{
				font-weight: bold;
				&:before{
					content: '"';
				}
				&:after{
					content: '"';
				}
			}
			& + div{
				margin-left: 0 !important;
				& > div:first-child{
					margin-bottom: rem-calc(15);
					font-size: rem-calc(17);
				}
			}
		}
	}
}
#nsearchError{
	.didyoumean{
		padding-left:rem-calc(15);
		& > .stress{
			font-size: rem-calc(25);
			margin-bottom: rem-calc(15);
		}
	}
	
}