.pro-block-grid{
	&.home-pro-grid	{
		padding: rem-calc(0 0 20 0);		
		> .row.column{
			@include breakpoint(medium down){
				padding: 0px;
			}
			> .column{
				padding: rem-calc(15);
				/*@include breakpoint(large){
					padding: rem-calc(30 10 10);
				}*/
			}
		}	
	}
	.pro-block{
		margin: rem-calc(15 0);                
		@include grid-column(1 of 2);
        @include breakpoint(medium down){
            padding:rem-calc(0 15);
        }
		@include breakpoint(medium){
			@include grid-column(1 of 3);
		}		
		&:last-child:not(:first-child){
			float:left;
		}
		.inner-box{			
			background: $white;
			padding: 10px;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			span{
				font-size: rem-calc(14); 
				color: $body-text;
				display: block;
				word-break: break-all;
				padding: rem-calc(10 0);
				@include breakpoint(large){
					font-size: rem-calc(18);
				}				
			}			
		}
		&:hover .inner-box{
			box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
			span{
				color: $primary-color;
			}
		}
	}
}

/***Testimonials New***/
.testimonials-wrap{
	background: $medium-gray;
	padding: rem-calc(40 0);
	.title-row{
		display: inline-block;
		width: 100%;
		padding-top: rem-calc(20);
		padding-bottom: rem-calc(20);
		h2{
			display: inline-block;
			font-size: rem-calc(30);
			color: $body-font-color;
			margin-left: rem-calc(20);
			margin-bottom: 0px;
			font-weight: 400;
		}
		.slider-controls{
			float: right;
            position:relative;
			span{
				width: rem-calc(40);
				height: rem-calc(40); 
				line-height: rem-calc(38);
				color:$white;
				font-size: rem-calc(30);
				text-align: center;
				background: #bababa;
				display: inline-block;
				margin-left: rem-calc(20);
                position:relative;
                margin-top:rem-calc(4);
                left:auto;
                right:auto;
			}
		}
	}
	.slides-wrap{
		display: inline-block;
		width: 100%;
        .swiper-container {
            width: 100%;
            height: 100%;
        }
        .swiper-wrapper{
            padding-bottom:rem-calc(10);
        }       
		.slider-slide{	
            box-sizing:border-box;		
            .slide-inner{
                padding: rem-calc(30 40 20);
                background: $white;
                box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
                margin-right:2px;
                display:inline-table;
                height:100%;
                width:100%;
            }
			p{
				color: $dark-gray;
				&.client-name{
					color: $light-gray;
					font-size: rem-calc(12);
				}
			}
            &.swiper-slide {             
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: unset;
                height:auto;
            }
		}
	}
}

/**Newsletter New***/
.bottom-blocks-row{
    @include breakpoint(medium down){
        > .column{
            padding:0px;            
        }        
    }
    .button{
        text-transform:uppercase;
    }
}
.bottom-blocks{
    padding:rem-calc(30);
    position:relative;
    min-height:rem-calc(246);
    background:red;
    @include breakpoint(medium down){
        margin-bottom:rem-calc(20);
        &.protection-wrap{
            margin-bottom:0px;
        }
    }
    @include breakpoint(large){
        padding:rem-calc(30 20);
        position:relative;
        /*.button{
            position:absolute;
            bottom:rem-calc(24);
        }*/
    }
    
    h2{
        color:$white;
        font-size:rem-calc(20);
        font-weight:400;
        line-height:normal;
        @include breakpoint(large){
            font-size:rem-calc(22);
            margin-top:rem-calc(10);
        }
    }
    .common-wrap{
        @include breakpoint(large){
            height:rem-calc(110);
            overflow:hidden;
        }
    }
}
.newsletter-wrap{
    background: url('../images/newsletter-bg.jpg') no-repeat top center;    
    background-size:cover;
    p{
        color:$white;
        font-size:rem-calc(15);
    }
    .form-newsletter{
		position: relative;
        /*@include breakpoint(large){
            position: absolute;
            bottom: rem-calc(10);
            width: 90%;
            padding-bottom:rem-calc(30);            
        }*/
		input[type=text]{
			border-radius: 0px;
            margin-bottom: 0px;
            width: 60%;
            max-width:rem-calc(210);
            float: left;
            height: rem-calc(40);
            font-size:rem-calc(14);
            color:$light-gray;
		}		
        .button.transperent{
            float:left;
            margin-left:rem-calc(10);
            margin-bottom:rem-calc(5);
            /*@include breakpoint(large){
                bottom: rem-calc(25);
            }*/
        }
		.newsletter-form__validation{
			color: red;			
			font-size: rem-calc(14);
			line-height: rem-calc(28);
            clear:both;
            /*@include breakpoint(large){
                position: absolute;
                bottom: 0px;
                width: 90%;
            }*/
		}
	}
}

.catalog-wrap{
    background: url('../images/catalog-bg.jpg') no-repeat top center;
    display:inline-block;
    width:100%;
    background-size:cover;
    overflow:hidden;
    .catalog-left{
        float:left;
        width:100%;
        padding-top:rem-calc(40);
        @include breakpoint(large){
            width:75%;
            padding-top:0px;
            /*.button{
                margin-top:rem-calc(10);
            }*/
        }
    }
    .catalog-right{
        position:absolute;
        right:rem-calc(-3);
        bottom:rem-calc(-3);
        float:right;
        width:43%;
        text-align:right;
        @include breakpoint(large){
            width:42%;
            right:rem-calc(0);
            bottom:rem-calc(-8);
        }        
    }
    @media only screen and (min-width: 1180px) {
        .catalog-right{
            width:49%;
            bottom:rem-calc(-10);
        }
    }
}
.row.bottom-blocks-row.column{
    @include breakpoint(medium down){
        padding:0px;
    }
    @include breakpoint(large){
        > .column{
            padding:rem-calc(0 3 0 0);
            &:last-child{
                padding-right:0px;
            }
        }
    }
}

.protection-wrap{
    background: url('../images/PP-Transtector-Blue.jpg') no-repeat center center;
    background-size:cover;
    @include breakpoint(large){
        /*.button{
            margin-top:rem-calc(37);
        }*/
    }
}


.button.transperent{
    background:none;
    border:1px solid $white;
    font-size:rem-calc(14);
    padding:rem-calc(12 20);    
}






$block-home-industries: '.home-industries-expertise';

#{$block-home-industries}{
	text-align: center;
	padding: rem-calc(100 0 60);
	border-bottom: 1px solid $border-gray;
	@include breakpoint(large){
		border:none;
		padding-bottom: rem-calc(40);
	}
	.home-industries-block{
		position: relative;
		display: block;
		margin-top: rem-calc(20);
		.img-block{
			img{
				width: 100%;	
			}			
		}
		.title{
			position: absolute;
			top: 0px;
			bottom: 0px;
			left: 0px;
			right: 0px;
			margin: auto;
			color: $white;
			a{
				position: absolute;
				top: 0px;
				bottom: 0px;
				width: 100%;
				display: block;
			}
			span{
				margin-top: 45%;
				display: block;
				font-size: rem-calc(30);
				height: rem-calc(60);
			}
			&:hover{
				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4476ac+0,60abf8+50,9d9ea1+100&0.8+0,0.8+100 */
				background: -moz-linear-gradient(45deg, rgba(68,118,172,0.8) 0%, rgba(96,171,248,0.8) 50%, rgba(157,158,161,0.8) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(45deg, rgba(68,118,172,0.8) 0%,rgba(96,171,248,0.8) 50%,rgba(157,158,161,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(45deg, rgba(68,118,172,0.8) 0%,rgba(96,171,248,0.8) 50%,rgba(157,158,161,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc4476ac', endColorstr='#cc9d9ea1',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
			}
		}		
	}
	> div{
		.column:last-child:not(:first-child){
			float: left;
		}
	}
}
.home-industries-expertise.home-expertise{
	background: url('../images/home-expertise/home-expertise-bg.jpg') no-repeat;
	background-size: 330%;
	background-position:80% bottom;
	padding-bottom: rem-calc(100);
	padding-top: rem-calc(60);
	@include breakpoint(medium){
		background-size: 200%;
		padding-top: rem-calc(100);
	}
	@include breakpoint(large){
		background-size:cover;
		background-position: center bottom; 
		padding-bottom: rem-calc(150);
	}
	h4{
		font-size:rem-calc(22);
		margin-top: 15%;
	}
	div.row{
		.column:last-child:not(:first-child){
			float: left;
		}		
	}	
}


$block-home-clients: '.home-clients';

#{$block-home-clients}{
	background: url('../images/home-clients/clients-bg.png') repeat;
	padding:rem-calc(65 0);
	text-align: center;
	@include breakpoint(large){
		text-align: left;
	}
	.clients{
		padding: rem-calc(30 0);
		img{
			margin: rem-calc(0 5 15);
		}
	}
	.white-bg{
		background: $white;
		@include breakpoint(large){
			background: none;
		}
	}
	.clients-list{		
		padding: rem-calc(10 0);
		display: inline-block;
		@include breakpoint(large){
			display: block;
			background: $white;
			text-align:center; 
		}
		img{
			margin:rem-calc(15 0);
			display: block;
			@include breakpoint(large){
				display: inline-block;
			}
		}
	}
}
