.filters-row{
	position: relative;
	.filter-wrappers{
		margin: rem-calc(0 -10);
		padding: rem-calc(0 10);
		border-bottom: 1px solid #cccccc;
		.filters-wrap{
			display: inline-block;
			width: 100%;
		}
		.filter-labels{
			float: left;
			position: relative;
			font-weight: 700;			
			margin-right: rem-calc(10);
			top: 5px;
		}
		.expertise-filter, .industry-filter{
			float: left;
			font-size: rem-calc(16);
			margin-right: rem-calc(10);
			cursor: pointer;
			border:1px solid #cccccc;
			border-radius: 5px;
			padding: rem-calc(5);
			span{				
				width: rem-calc(40);
				white-space: nowrap;
				overflow: hidden;
				float: left;
				text-overflow:ellipsis;
				padding: rem-calc(0 5);				
				@include breakpoint(medium){
					width: rem-calc(200);
				}
			}
			@include breakpoint(large){
				position: relative;
			}
			i{
				margin-left: rem-calc(5);
			}
		}
		.show-all{
			float: right;
			color: $secondary-color;
			font-weight: 700;
			position: relative;	
			top: 5px;		
		}	
	}
	.drop-down-wrap{
		display: none;
		margin: rem-calc(0 -10);
		background: $white;
		position: absolute;
		z-index: 1;
		left: 10px;
		right: 10px;
		top: 36px;
		border-radius:5px;	
		box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.5);
		table{
			border-collapse: collapse;
			margin: 0px;
			tbody{
				background: none;
				border:none;
				tr{
					background: none;
					td{
						padding: 0px;
						input{
							margin: 0px;
							position: absolute;
							top: 3px;
							opacity: 0;
						}
						label{
							padding: rem-calc(5 10);							
							margin: 0px;
							display: block;
							&.active{
								color: $secondary-color;
							}							
						}
					}
					&:last-child{
						td{
							label{
								border-bottom: none;		
							}							
						}
					}
				}
			}
		}
		@include breakpoint(large){
			width: rem-calc(220);
		}		
	}	
}




.portfollio-filter-results{
	padding: rem-calc(40 0);
	.portfolio-block{
		box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.1);
		margin: rem-calc(0 0 40 0);
		.img-block{
			@include breakpoint(large){
				//height: rem-calc(240);
				img{
					height: 100%;
				}
			}
			img{
				width: 100%;
			}

		}
		.description-block{
			padding: rem-calc(20 20 80 20);
			position: relative;
			.title{
				@include breakpoint(large){
					height: rem-calc(70);
				}

				h2{
					margin:rem-calc(0 0 15 0);
					line-height: 1;
				}
				a{
					color: $black;
					font-size: rem-calc(18);
					font-weight: 700;
					margin: rem-calc(10 0);					
				}
			}
			.short-desc{
				font-size: rem-calc(15);
				color: $black;
				@include breakpoint(large){
					height: rem-calc(160);
				}
			}
			.link-location{
				position: absolute;
				left: 0px;
				bottom: -5px;
				right: 0px;
				.button{
					width: 100%;
					text-transform: uppercase;
					font-weight: 900;
					margin: 0px;
					font-size: rem-calc(16);
					padding: 0px;
					height: rem-calc(50);
					line-height: rem-calc(50);
				}
			}			
		}
	}
	> div{
		.column:last-child:not(:first-child){
			float: left;
		}
	}
	.column:last-child:not(:first-child){
		float: left;
	}
}

.portfollio-filter-results.industry-listing .portfolio-block .description-block .short-desc{
	@include breakpoint(large){
		height: rem-calc(210);
	}
}



