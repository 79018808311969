.large-font{
	font-size: rem-calc(18);
}
.section-title{
	color: $secondary-color;
	font-size: rem-calc(15);
	font-weight: 900;
	margin-bottom: rem-calc(6);
}
.form-row{
	padding-bottom: rem-calc(15);
}
.section-content{
	table{
		//border: none;
		tbody{
			background:none;
			border: none;
		}
		tr{
			background: none;			
			@media only screen and (min-width: 400px){
				width: 50%;
				display: inline-block;
			}
			&:nth-child(odd){
				background: none;
			}
			td{
				color: #555555;
				font-size: rem-calc(15);
				padding: 0px;
				display: block;				
			}
		}
		&.uploadergrid{
			tr{
				display: table-row;
				width: auto;
				border: 1px solid #ccc;
				border-radius: 5px;
				td{
					padding: rem-calc(3);
					display: table-cell;
				}
			}			
		}		
	}
	input{
		margin-bottom: 0px;
		&[type=radio], &[type=checkbox]{
			position:relative;
			top: 1px;
		}
		&[type=radio]:before{
			background: url(../images/radio-btn-bg.png) no-repeat center bottom;
			content: ' ';
			width: rem-calc(17);
			height: rem-calc(17);		
			display: inline-block;
			position: relative;
			top: -1px;
			left: -1px;
		}
		&[type=radio]:checked:before{
			background: url(../images/radio-btn-bg.png) no-repeat center top;
		}
		&[type=checkbox]:before{
			background: url(../images/checkbox-btn-bg.png) no-repeat center bottom;
			content: ' ';
			width: rem-calc(17);
			height: rem-calc(17);		
			display: inline-block;
			position: relative;
			top: -1px;
			left: -1px;
		}
		&[type=checkbox]:checked:before{
			background: url(../images/checkbox-btn-bg.png) no-repeat center top;
		}
	}
	textarea{
		margin-bottom: 0px;
	}
	label{
		//margin-left: rem-calc(15);		
		color: #555555;	
		font-weight: 500;		
	}
	p{
		color: #555555;
		font-size: rem-calc(15);	
		margin-bottom: rem-calc(5);
	}
	.uploaderprogresstable{
		width: 100%;
		tr{		
			width: auto;
			display: table-row;
			@include breakpoint(medium){
				width: auto;
				display: table-row;
			}
		}
	}
}
.button-row{
	text-align: right;
	.button{
		width: 100%;
		max-width: rem-calc(360);
		text-transform: uppercase;
	}
}

.solution-row{
	.section-content{
		table{						
			tr{
				width: auto;
				display: table-row;
				@include breakpoint(medium){
					width: 50%;
					display: inline-block;
				}
			}
		}
	}
}


input[type=text],input[type=password], select, textarea{
	border-radius: 5px;
}
.upload-btn{
	width: 100%;
	overflow: auto;
	table{
		min-width: auto !important;
		width: 100% !important;
		tr{
			margin-bottom: rem-calc(10);
			td{
			 	width: 80%;
			}
			td:first-child,td:last-child{
				width: 10%;
				text-align: center;
			}
		}
	}
	legend{
		width: 100%;
	}
	.button{
		margin: rem-calc(20 0);		
		text-transform: uppercase;
		width: 100%;
		max-width: rem-calc(360);
	}
}
.upload-btn {
	.file-list-field{
	  border: 3px dotted #eee;
	  height: 9.375rem;
	  overflow: auto;
	  box-shadow: none;
	}
	.uploadercancelbutton{		
		text-transform: uppercase;
		font-size: rem-calc(14);
		margin: rem-calc(10 0);
		background-color: $medium-gray; 		
		color: $black;
		transition:background-color .25s ease-out,color .25s ease-out;
		border: none;				
		display: inline-block;	
		max-width: rem-calc(220);			
		padding: rem-calc(10 20);					
		&:hover{
			background: $border-gray;
		}		
	}
}
.uploaderprogressinfotext{
	width: 100%;
	display: block;
	clear: both;	
}
.required{
	color: $secondary-color;
}