.page-title.capitilize {
    h1 {
        font-size: rem-calc(30);
    }
}

.news-items.white-papers {
    &.blog-posts {
        .news-item {
            .news-head {
                a.news-title {
                    padding-left: 0px;
                }
            }
        }
    }
}

.post-date {
    font-weight: 500;
}
.news-item {
    border-top: 1px solid $left-nav-ul-border;
    padding: rem-calc(25 0 0);
}
.news-head {
    a.news-title {
        font-size: rem-calc(20);
        font-weight: 800;
        color: $dark-gray;
        padding-left: rem-calc(28);
        position: relative;
        display: inline-block;
        img {
            position: absolute;
            left: 0px;
            top: 7px;
        }
    }
}

.news-body {
    padding: rem-calc(20 0 20 28);
    @include breakpoint(large) {
        padding: rem-calc(20 0);
    }
    .content-description {
        padding-bottom: rem-calc(20);
        &.blg-detail{
            img.post-thumbnail{
                width: 100%;
                margin-bottom:rem-calc(20);
                @include breakpoint(769){
                    width: auto;
                    float: left;
                    margin:rem-calc(0 20 10 0);
                }
            }
        }
    }
}

.share-buttons-wrap {
    padding: rem-calc(0 0 10 50);
    position: relative;
    .label-text {
        font-weight: 800;
        position: absolute;
        left: 0px;
        top: 8px;
        font-size: rem-calc(14);
    }
    .social-buttons {
        a {
            display: inline-block;
            border: 1px solid $left-nav-ul-border;
            margin: rem-calc(5);
            height: rem-calc(28);
            width: rem-calc(28);
            text-align: center;
            line-height: rem-calc(26);
        }
    }
    .tags-block {
        a {
            display: inline-block;
            background: $medium-gray;
            color: $body-text;
            padding: rem-calc(5 10);
            margin: rem-calc(5);
            font-size: rem-calc(13);
            font-weight: 500;
        }
    }
}

.pager {
    float: none;
    padding: rem-calc(25 10);
    text-align: center;
    border-top: 1px solid $left-nav-ul-border;
}

/*.blogpost-page {
    border-bottom: 1px solid $left-nav-ul-border;
}*/
