.checkout-page{
	.buttons{
		.button{
			padding:rem-calc(12 16);
			text-transform: uppercase;
			font-weight: 600;
			margin-top: rem-calc(15);
		}
	}
	.checkout-data{
		#checkout-steps{
			.billing-shipping-wrap{
				.billing-shipping-block{
					.select-billing-address{
						// label{
						// 	font-weight: 600;
						// }
					}
					.section{
						.edit-address{
							.inputs{
								padding-bottom: rem-calc(10);
								select{
									margin-bottom: 0;
								}
							}
						}
					}
					
				}
			}
			#opc-shipping_method{
				#shippingMethodSuccessMessage{
					padding: rem-calc(0 15 15 15);
				}
				#divCustomShippingMethod{
					display: flex;
					select{
						margin-right: rem-calc(10);
					}
					input[type="text"]{
						vertical-align: top;
					}
				}
			}
			#opc-payment_method{
				width: 100%;
				.paymethod-wrap{
					.step-title-in{
						padding:rem-calc(0 10);
						font-weight: 600;
					}
					.pay-inner-wrap{
						@include grid-row;
						.column-select{
							@include grid-column(6,10);
							@include breakpoint(medium down){
								@include grid-column(12,10);
							}
							.method-list{
								li{
									@include breakpoint(small only){
										padding:rem-calc(10 0);
									}
								}
								@include breakpoint(large up){
									display: flex;
								}
							}
							.method-name{
								border: 1px solid #ddd;
							    padding:rem-calc(10);
							    line-height: normal;
							    background: #f9f9f9;
							    border-radius: 5px;
							    display: flex;
							    align-items:center;
							    min-height: rem-calc(70);
							    @include breakpoint(small only){
							    	width: 100%;
							    }
							    .payment-logo{
							    	margin-right: rem-calc(10);
									img{
										max-width: rem-calc(45);
										vertical-align: middle;
										cursor: pointer;
									}
								}
							}
							@include breakpoint(small only){
								.po-number-wrap{
									display: block;
									tbody{
										display: block;
										border:0;
										tr{
											display: block;
											td{
												display: block;
												text-align: left;
												&:first-child{
													padding-bottom:0;
												}
												&:last-child{
													padding-top:0;
												}
											}
										}
									}
								}
							}
						}
						.column-crd-crd{
							@include grid-column(6,10);
							@include breakpoint(medium down){
								@include grid-column(12,10);
							}
						}
						#paymentSuccessMessage{
							padding: rem-calc(0 15 15 15);
						}
					}
				}
				#checkout-step-payment-info{
					.payment-info{
						@include breakpoint(small only){
							table,tbody,tr,td{
								display: block;
								text-align: left;
								padding:0;
							}
							td{
								overflow-x:auto;
								margin-left: rem-calc(-15);
							}
						}
					}
				}
			}
			.step-place-order{
				border:none;
				.button{

				}
			}
			#opc-confirm_order{
				.order-summary-content{
					.cart{
						thead{
							background:$dark-gray;
						}
					}
				}
			}
			#opc-fcc-required{
				.chkbox-wrap{
					margin-top: rem-calc(8);
					label{
						margin:rem-calc(0 0);
					}
					input[type="checkbox"],
					input[type="radio"]{
						position: relative;
						top:2px;
						margin:0;
					}
				}
				.sep-bottom{
					margin-bottom: rem-calc(20);
				}
			}
		}
	}
}

.order-completed-page{
	padding:rem-calc(70 0);
	text-align:center;
	@include breakpoint(small only){
		padding:rem-calc(40 0);
	}
}

.order-details-page{
	.products{
		border:1px solid $primary-color;
		margin-bottom: rem-calc(10);
		.title{
			background:$primary-color;
			color: $white;
			text-transform: uppercase;
			padding: rem-calc(8 10);
		}
		table{
			margin-bottom: 0;
			@include breakpoint(medium){
				th.price,th.total,td.unit-price,td.total{
					text-align: right;
				}
				th.quantity, td.quantity{
					text-align: center;
				}
			}
		}

	}
	@include breakpoint(small only){
		.table-wrapper{
			 tbody{
			 	    padding: 10px;
				    display: block;
				    background: transparent;
				    border: 0;
			 	tr{
			 		list-style-type: none;
			 		margin-bottom: rem-calc(20);
			 		margin-left: 0;
			 		&:nth-child(even){
			 			background:transparent;
			 		}
			 		td.sku{
			 			font-weight: 600;
			 		}
			 	}
			 }
		}
	}
	
}