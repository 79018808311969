.left-navigation-topic{    
    margin-bottom:rem-calc(30);    
    background:$white;
    h3{
        background:$primary-color;
        font-size:rem-calc(16);
        padding:rem-calc(15 22);
        margin-bottom:0px;
        color:$white;
        text-transform:uppercase;
    }
    > ul{
        margin:0px;
        padding:rem-calc(10 0);
        list-style:none;
        /*border:1px solid $left-nav-ul-border;*/
        >li{
            position:relative;
            > a{
                font-size:rem-calc(15);
                padding:rem-calc(10 36 10 20);
                font-weight:700;
                text-transform:uppercase;
                color:$dark-gray;
                display:block;
                position:relative;
                &:hover, &.active{
                    color:$primary-color;
                }                
            }
            > span{
                position:absolute;
                right:rem-calc(15);
                top:rem-calc(14);
                line-height:rem-calc(16);
                cursor:pointer;
                &.more{
                    .fa-minus{
                        display:none;
                    }
                }
                // &.more{
                //     .fa-minus{
                //         display:none;
                //     }
                //     &.less{
                //         .fa-plus{
                //             display:none;
                //         }
                //         .fa-minus{
                //             display:inherit;
                //         }
                //     }
                // }
            }
            &.active{
                >span{
                    &.more{                        
                        .fa-plus{
                            display:none;
                        }
                        .fa-minus{
                            display:inherit;
                        }                        
                    }
                }
                ul.sub-nav{
                    display: block;
                }
            }
        }
    }
    ul.sub-nav{
        margin:0px;
        padding:0px;
        list-style:none;
        display:none;
        li{
            a{
                font-size:rem-calc(15);
                padding:rem-calc(10 20 10 30);
                color:$left-nav-sub-text;                
                display:block;
                &:hover, &.active{
                    color:$primary-color;
                }
            }
        }
    }
    > ul.recent-post{
        >li{
            div.title{
                font-size:rem-calc(16);
                margin:rem-calc(0 20);
                padding:rem-calc(15 0);
                color:$dark-gray;
                border-bottom:1px solid $left-nav-ul-border;
                /*a{
                    color:$dark-gray;
                    &:hover{
                        color:$primary-color;
                    }
                }*/
            }
            &:last-child{
                div.title{
                    border-bottom:none;
                }
            }
        }
        &.recent-blogs{
            >li{
                div.title{
                    /*a{
                        padding:0px;
                        color:$dark-gray;
                        &:hover{
                            color:$primary-color;
                        }
                    }*/
                }
            }
        }
    }   
    > ul.search-box{
        padding:0px;
        li{
            input[type=text]{
                border: none;
                height: rem-calc(48);
                padding: rem-calc(0 50 0 10);
                border-radius: 0px;
                box-shadow: none;
            }
            .search-btn{
                height: rem-calc(48);
                width: rem-calc(48);
                position: absolute;
                right: 0px;
                top: 0px;
                border: none;
                background: transparent;
                border-left: 1px solid $left-nav-ul-border;
                color:$primary-color;
            }
        }
    } 
}
.marketing-blocks{
    margin:rem-calc(20 0);
}
img.full-width{
    width:100%;
}
.page.not-found-page{
    margin:rem-calc(30 0);
}
.table-clear-bg{
    width:100%;
    &.test-lab{
        max-width:rem-calc(565);
    }
    tbody{
        tr,tr:nth-child(odd),:nth-child(even){
            background:none;
        }
    }
    th, td{
        background:none;
    }
}
.channel-team-table{
    @include breakpoint(medium down){
        td,th{
            font-size: rem-calc(14);
        }
    }    
    td,th{
        text-align:left;
        vertical-align:top;
        padding:rem-calc(0 10 0 0);
        width:33%;
    }
}
.channel-video-row{
    .column{
        a{
            margin-bottom:rem-calc(10);
            display:inline-block;
        }
    }
}
.table-spacing-small{
    td,th{
        text-align:left;
        vertical-align:top;
        padding:rem-calc(0 10 0 0);        
    }
}
table.test-lab{
    td,th{
        width:25%;
    }
}
/**Motorola Guide table*/
.motorola-ordering-guide-wrap{
    overflow: auto;
    > table{
        font-size: rem-calc(12);
        border-collapse: collapse;
        tr.table-headings,{
            text-align: left;            
            color: $white;
            background: $primary-color;
            font-size: rem-calc(14);
            font-weight: 500;
            &:nth-child(even){
                background: $primary-color;
            }
        }
        tr{
            td{
                border: 1px solid #d8d8d8;
                &:nth-child(1),&:nth-child(2),&:nth-child(4),&:nth-child(5),&:nth-child(6){
                    white-space: nowrap;
                }                
                strong{
                    white-space: nowrap;
                }
            }
            &:nth-child(even){
                background: none;                
            }
            &.even{
                background: #f2f2f2;
            }
        }
    }
}