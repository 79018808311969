﻿h2.blue-bg-title{
    background:#012955;
    color:$white;
    font-size:rem-calc(20);
    font-weight:500;
    padding:rem-calc(8 15);
    margin-bottom:0px;
    text-align:center;
    @include breakpoint(medium){
        font-size:rem-calc(30);
    }
}
.image-block {
  position: relative;
    h3.banenr-title {      
      padding: rem-calc(10 15);
      background: rgba(0,127,214,1);
      color: $white;
      font-size: rem-calc(20);
      text-align: center;
      font-weight: 500;      
      margin-bottom:0px;
      @include breakpoint(medium){
          position: absolute;
          top: 20%;
          left: 25%;
          background: rgba(0,127,214,0.8);
          width: 50%;
          padding: rem-calc(25);
          font-size: rem-calc(26);
      }
      @include breakpoint(large){
          padding: rem-calc(40 80);
          width:60%;
          left:21%;
          font-size: rem-calc(40);
      }
    }
}


.resources-block-grid{	
    margin-top:rem-calc(30);
    margin-bottom:rem-calc(10);
    .medium-gray{
        padding:rem-calc(25 15);
        @include breakpoint(large){
            padding:rem-calc(25 40);
        }        
    }
    h2{
        color:#002e60;
        font-size:rem-calc(20);
        font-weight:300;
        padding:rem-calc(0 15);
        text-transform:uppercase;
    }
	.resources-block{
		margin-bottom: 20px;
		@include grid-column(1 of 1);
        @include breakpoint(medium){
			@include grid-column(1 of 2);
		}
		@include breakpoint(large){
			@include grid-column(1 of 4);
		}		
		&:last-child:not(:first-child){
			float:left;
		}
		.inner-box{			
			background: $white;
			padding: 10px;
			text-align: center;
            border:1px solid $darker-new-gray;
            display:block;
            .img-blk{
                padding:rem-calc(40 0);
                svg{
                    max-width:100%;
                    width:rem-calc(140);
                    height:rem-calc(90);
                    .cls-1,.cls-2{
                        fill:#bcbec0;
                    }
                    .cls-2{
                        stroke:#fff;
                        stroke-miterlimit:10;
                        stroke-width:3px;
                    }
                }
            }
			span{
				font-size: rem-calc(16); 				
				display: block;
				word-break: break-all;
				padding: rem-calc(10 0);							
			}			
		}
		&:hover .inner-box{
            border:1px solid $primary-color;
            background:$primary-color;
			box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.4);
            .img-blk{
                svg{
                    transform: rotateY(360deg);
                    transition: transform ease-out .3s;
                    .cls-1,.cls-2{
                        fill:#fff;
                    }
                    .cls-2{
                        stroke:$primary-color;
                    }
                }
            }
			span{
				color: $white;
			}
		}
	}
}

