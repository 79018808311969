header{
    position: relative;
    z-index: 999;
}
    .pre-loader{
        text-align:center;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin:auto; 
        background: rgba(255,255,255,0.9);
        z-index: 700;
    }
    .prl-in{
        height: 150px;
        width: 200px;
        margin: auto;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    .prl-in h4{
        margin-top: -15px;
    }
    .pre-loader img{
        max-width:100px;
    }

.category-all{
	.breadcrumb{
		padding-left: rem-calc(15);
		padding-right: rem-calc(15);
		margin-bottom: rem-calc(20);
	}
	.home__category-grid{
		@include grid-row;
		.home__category-grid__item{
			margin-bottom:rem-calc(20);
			@include grid-column(12, 20);
			@include breakpoint(medium){
				@include grid-column(3, 20);
			}
			@include breakpoint(large){
				@include grid-column(2, 20);
			}
			&:last-child:not(:first-child){
				float: left;
			}
			.home__category-grid__link{
				border: 2px solid #ddd;
				display: block;
				text-align: center;
				color:$dark-gray;
				padding:rem-calc(15);
				box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
				transition: all linear 0.1s;
				&:hover{
					box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
					color: $primary-color;
				}
				
			}
		}
	}
}