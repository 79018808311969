.admin-header-links {
    display: none;
}

  
.top-grey-row {
    background: $medium-gray;
    font-size: rem-calc(14);
    position: relative;
    top: -1px;
    padding-top:1px;
    .currency {
        /*display: none;*/
        text-align: right;
        @include breakpoint(medium) {
            text-align: left;
            /*display: block;*/
        }
        span {
            display: inline-block;
            height: rem-calc(30);
            line-height: rem-calc(30);
        }
        select {
            height: rem-calc(30);
            line-height: rem-calc(30);
            border: none;
            border-radius: 0px;
            font-size: rem-calc(14);
            padding: rem-calc(0 5);
            max-width: rem-calc(120);
            margin-left: 5px;
            margin-bottom: 0px;
        }
    }
    .cart-column {
        text-align: right;
        @include breakpoint(large){
            padding-top:rem-calc(5);
            padding-bottom:rem-calc(5);
        }
        .mini-cart {
            float: right;
            //margin-left: 10px;
            // padding-left: 6px;
            //border-left: 1px solid #d5d5d5;
            position: relative;
            i {
                margin-right: 10px;
                color: $primary-color;
            }
            &:hover {
                .dropdown-pane {
                    display: block;
                }
            }
            .dropdown-pane {
                display: none;
                position: absolute;
                background: $white;
                width: rem-calc(260);
                top: 30px;
                right: 0px;
                z-index: 11111;
                box-shadow: 0px 3px 7px 1px $darker-gray;
                padding: 20px;
                max-height: rem-calc(450);
                overflow-x: auto;
            }
            .mini-shopping-cart {
                .item {
                    padding: rem-calc(5 0);
                    border-bottom: 1px solid $darker-gray;
                    a {
                        line-height: normal;
                        margin: 0px;
                        display:inline-block;
                        border:none;
                    }
                    .picture {
                        float: left;
                        width: 70px;
                        text-align: left;
                        img{
                            width:rem-calc(60);
                        }
                    }
                    .product {
                        padding-left: rem-calc(75);
                    }
                }
                .buttons{
                    .button{
                        font-size:rem-calc(14);
                    }
                }
            }
        }
        .login-detials {
            float: right;
            .login-blk {
                display: inline-block;
            }
            > a.no-border{
                border-left:0px;
                padding-left:0px;
            }
        }
        a {
            /*line-height: rem-calc(30);
            height: rem-calc(30);*/
            margin-left: rem-calc(10);
            border-left:1px solid #d1d1d1;
            padding-left:rem-calc(10);
            color: $dark-gray;
            &:hover {
                color: $primary-color;
            }
        }
    }
}

.logo-row {
    padding: rem-calc(13 0 7 0);
    .logo-column {
        a.header__logo__link {
            float: left;
            width: rem-calc(190);
            margin-top:rem-calc(10);
        }
        .mob-header {
            font-size: rem-calc(36);
            float: right;
            a {
                cursor: pointer;
            }
        }
        @include breakpoint(large) {
            a.header__logo__link {
                width: auto;
                float: none;
                display: inline-block;
                margin: rem-calc(28 0);
            }
            .mob-header {
                display: none;
            }
        }
    }
    .customer-support-col {
        display: none;
        @include breakpoint(large) {
            display: flex;
        }
        .tel-number {
            font-weight: 400;
            margin-top: rem-calc(27);
            a {
                font-size: rem-calc(16);
                color: $light-gray;
                text-transform: uppercase;
                span {
                    font-size: rem-calc(20);
                    font-weight: 900;
                    color: $body-text;
                    display: block;
                }
            }
            &:last-child{
                margin-left: rem-calc(42);
            }
        }
    }
    .button.contact-us{
        margin-top:rem-calc(27);
        position: relative;
        padding:rem-calc(0 30);
        height:rem-calc(50);
        line-height:rem-calc(50);
        i{
            position: relative;
            left: rem-calc(-4);
            font-size: rem-calc(25);
            top: rem-calc(2);
        }
    }
}

.search-column {
    padding: rem-calc(1);
    background: $dark-gray;
    margin: rem-calc(10 0 0);
    .header__main-menu__search {
        position: relative;
        input[type=text] {
            border-radius: 0px;
            box-shadow: none;
            padding-right: 46px;
            margin-bottom: 0px;
            height:rem-calc(46);
        }
        .header__main-menu__search__button {
            position: absolute;
            color: $white;
            right: 0px;
            top: 0px;
            width: rem-calc(46);
            height: rem-calc(46);
            background: $primary-color;
            border: 1px solid #8c8c8c;
        }
    }
    @include breakpoint(large) {
        padding: rem-calc(0 15);
        margin: 0px;
        position: relative;
        .header__main-menu__search-form {
            position: absolute;
            left: -15px;
            width: 100%;            
            background: $black;
            padding: rem-calc(2 0 2 12);
        }
        .header__main-menu__search {
            input[type=text] {
                height: rem-calc(56);
                // line-height: rem-calc(56);
                padding-right: rem-calc(80);
                font-size: rem-calc(20);
            }
            .header__main-menu__search__button {
                height: rem-calc(60);
                width: 60px;
                border: 0px;
                top: -2px;
                font-size: rem-calc(25);
            }
        }
    }
}

@include breakpoint(large) {
    .navigation-search-bar {
        background: $black;
        margin: rem-calc(15 0 0 0);        
    }
}


/***Navigation***/

.main-navigation {
    //display: none;
    position: fixed;
    z-index: 111;
    top: 0;
    bottom: 0;
    right: -400px;
    max-width: rem-calc(350);
    width:85%;
    background: $dark-gray;
    padding: 0;
    overflow-x: scroll;
    box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.5);
    
    .call-us-mob-anchor {
        background: $secondary-color;
        color: $white;
        display: block;
        padding: rem-calc(5 0);
        text-align: center;
        font-size: rem-calc(14);
        font-weight: 700;
        i {
            font-size: rem-calc(14);
            margin-right: rem-calc(3);
        }
        @include breakpoint(large) {
            display: none;
        }
    }
    .mob-close {
        font-size: rem-calc(25);
        color: $white;
        display: inline-block;
        margin: rem-calc(10 20);
        @include breakpoint(large) {
            display: none;
        }
    }
    .social-icons {
        margin: rem-calc(20);
        a {
            color: $white;
            font-size: rem-calc(20);
            margin-right: rem-calc(20);
        }
        @include breakpoint(large) {
            display: none;
        }
    }
    @include breakpoint(medium down){
        > ul {
            margin-top:rem-calc(20);
        }
    }
    ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
        li {
            border-bottom: 1px solid #4e4e4e;
            position: relative;
            a {
                color: $white;
                display: block;
                padding: rem-calc(15 20);
                text-transform: uppercase;
                &:hover,
                &.active {
                    background: $secondary-color;
                }
                i {
                    display: none;
                }
                > span{
                    @include breakpoint(medium down){
                        color:#dddddd;
                        text-transform:capitalize;
                    }                    
                }
                &.live-chat-mob{
                    i{
                        display:inline-block;
                        margin-right:rem-calc(7);
                    }
                }
            }
            span.dd-caret {
                position: absolute;
                right: 0;
                //background: $secondary-color;
                color: $white;
                text-align: center;
                top: 0;
                cursor: pointer;
                padding: rem-calc(15);
                i {
                    &.fa-angle-down:before {
                        content: "\f107";
                    }
                    &.fa-angle-down.fa-angle-up:before {
                        content: "\f106";
                    }
                }
                @include breakpoint(large) {
                    display: none;
                }
            }
            >ul.menu {
                display: none;
                background: $dark-gray;
                >li {
                    display: block;
                    &:last-child {
                        border: none;
                    }
                    >a {
                        font-size: rem-calc(14);
                        border-bottom: 1px solid $darker-gray;
                    }
                    span.dd-caret {
                        padding: rem-calc(10 15 9);
                        //background: #666;							
                    }
                    >.header__mega-menu__sub-menu {
                        display: none;
                        background: $dark-gray;
                        text-align: center;
                        text-transform:capitalize;
                        font-weight:500;
                        @include breakpoint(large) {
                            width: rem-calc(240);
                            left: auto;
                            right: -240px;
                            top: 0;
                            text-align: left;
                        }
                        >.header__mega-menu__sub-menu__header{
                            display: none;
                            @include breakpoint(large){
                                display: block;
                            }
                        }
                        > ul{
                            text-align: left;
                            >li{
                                font-size: rem-calc(14);
                            }
                        }
                    }
                }
            }

            
            &.services{
                ul.menu{
                    li{
                        &.hovered{
                            .header__mega-menu__sub-menu{
                                 min-width: rem-calc(250);
                                min-height: auto;
                                ul.header__mega-menu__sub-menu__inner{
                                    margin-right:0;
                                }
                            }
                        }
                    }
                }
            }


        }
    }
    @include breakpoint(large) {
        position: relative;
        background: none;
        border: none;
        width: 100%;
        margin: rem-calc(0);
        text-align: left;
        display: block;
        left: 0px !important;
        box-shadow: none;
        overflow-x: visible;
        .header__mega-menu__sub-menu {
            display: none;
        }
        ul {
            li {
                display: inline-block;
                border-bottom: none;
                a {
                    color: $white;
                    font-size: rem-calc(18);
                    padding: rem-calc(0 15);
                    line-height: rem-calc(58);
                    height: rem-calc(58);
                    margin-top: 2px;
                    i {
                        display: inline-block;
                        margin-left: rem-calc(10);
                    }
                }
                ul.menu {
                    position: absolute;
                    top: 60px;
                    left: 0;
                    display: none;
                    background: $white;
                    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.2);
                    width: rem-calc(250);
                    li {
                        display: block;
                        padding-bottom: 0;
                        margin-right: rem-calc(0);
                        >a {
                            color: $dark-gray;
                            display: block;
                            border-bottom: 1px solid #dddddd;
                            font-size: rem-calc(18);
                            line-height: normal;
                            height: auto;
                            padding: rem-calc(10 40 10 15);
                            position: relative;
                            i {
                                color: #dddddd;
                                position: absolute;
                                top: 50%;
                                margin-top: -10px;
                                right: 15px;
                            }
                            &:hover{
                                color: $primary-color;
                            }
                        }
                        &.hovered {
                            .header__mega-menu__sub-menu {
                                position: absolute;
                                left: 100%;
                                display: block;
                                top: rem-calc(-3);
                                background: $white;
                                padding: rem-calc(10);
                                border: 1px solid #ddd;
                                min-width: rem-calc(360);
                                min-height: rem-calc(230);
                                box-shadow:2px 4px 3px rgba(0, 0, 0, 0.2);
                                img.header__mega-menu__sub-menu__image {
                                    position: absolute;
                                    right: 10px;
                                    top: 70px;
                                }
                                .header__mega-menu__sub-menu__header {
                                    background: $dark-gray;
                                    color: $white;
                                    padding: rem-calc(5 15);
                                    border-radius: 3px;
                                    font-weight: normal;
                                    font-size: rem-calc(18);
                                }
                                ul.header__mega-menu__sub-menu__inner {
                                    margin-right: rem-calc(160);
                                    margin-top: 20px;
                                    li {
                                        a {
                                            font-size: rem-calc(18);
                                            padding: rem-calc(5 15);
                                            height: auto;
                                            line-height: normal;
                                            font-weight: normal;
                                        }
                                        &:last-child {
                                            a {
                                                border: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.hovered {
                    a {
                        background: $white;
                        color: $dark-gray;
                    }
                    ul.menu {
                        display: block;
                    }
                }
                &.products.hoveredCategory{
                    >a:hover, >a {
                        background: none;
                        color: $white;
                    }
                    ul.menu {
                        display: block;
                    }
                }

            }
        }
    }
}

/*.html-category-page,
.html-topic-page,body.ordersku{
    @include breakpoint(large) {
        .main-navigation ul li.products{
           & > a {
                background: $dark-gray;
                color: $white;
                i{
                    color: $white;
                }
            }
        }
        .main-navigation ul li.products ul.menu {
            display: block;
        }
    }
}*/


/***Navigation ends***/