.footer{
    background: $black;
    padding: rem-calc(40 0);
    color: #bbbbbb;
    font-weight: 300;
    font-size: rem-calc(15);
    .footer-top{
        padding-bottom:rem-calc(25);
        .foot-columns{            
            @include breakpoint(large){
                padding-bottom: rem-calc(0);
            }
            @include breakpoint(medium down){
                padding:rem-calc(10 30);
                border-bottom:1px solid #444444;
            }
        }
    }
    .footer-bottom{
        @include breakpoint(large){
            border-top:1px solid #555555;
        }        
        padding-top:rem-calc(25);
        font-size: rem-calc(14);
        @include breakpoint(medium down){
            text-align:center;            
        }
        .copright-column{
            padding-top:rem-calc(10);
            padding-bottom:rem-calc(10);
            font-size:rem-calc(12);
            color:#bbbbbb;
        }
        .infinite-logo{
            text-align: center;
            padding: rem-calc(9 0);
        }
        .social-icons{
            @include breakpoint(large){
                text-align: right;
            }
            a{
                margin-left:10px;
                font-size: rem-calc(30);
            }
        }
    }
    a{
        color: #bbbbbb;
    }
    ul{
        list-style: none;
        padding:0px;
        margin: 0px;        
        @include breakpoint(medium down){
            padding: rem-calc(0 10);
            display:none;
        }
        @include breakpoint(large){
            display:block !important;
            line-height: normal;
            li{
                margin-bottom: rem-calc(10);
                a:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    h5{
        text-transform: uppercase;
        font-size: rem-calc(18);
        margin-bottom:rem-calc(10);
        color: $white;
        @include breakpoint(medium down){
            line-height: 100%;
            padding: rem-calc(10);
            margin-bottom:0px;
        }
        i{
            float:right;
            @include breakpoint(large){
                display:none;
            }
        }
        a{
            color: $white;
        }
    }
}