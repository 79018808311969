.contact-page{
	.content-area{
		margin-bottom: rem-calc(50);
	}
	.bordered-bottom{
		padding-bottom: rem-calc(50);
		@include breakpoint(large){
			padding-bottom: 0px;
		}
	}	
    .foot-columns{
        h5{
            margin-top:rem-calc(10);
            font-size:rem-calc(16);
            font-weight:500;
            margin-bottom:0px;
        }
    }
}

.site-form{
	padding-top: rem-calc(30);
	@include breakpoint(large){
		box-shadow: 0px 0px 1px 2px rgba(0,0,0,0.1);
		padding: rem-calc(50 40);
		border-radius: 2px;
		margin-top: rem-calc(10);
	}
	p{
		font-weight: 900;
		color: $black;
	}
	label{
		font-size: rem-calc(15);
		color: $black;
		display: inline-block;
		margin-right: rem-calc(2);
		font-weight: 500;
		span.ErrorLabel{
			font-weight: 300;
		}
	}
	input[type=text],select,textarea{
		border:1px solid $medium-gray;
		height: rem-calc(44);
		line-height: rem-calc(44);
		color: $black;
		border-radius: 5px;
	}
	select{
		background-image: none;
	}
	textarea{
		height: rem-calc(100);
		line-height: normal;
		@include breakpoint(large){
			height: rem-calc(120);
		}
	}
	.button-row{
		text-align: center;
		@include breakpoint(large){
			text-align: right;
		}
	}
	.button{
		width: 100%;
		max-width: rem-calc(360);
		font-weight: 700;
		font-size: rem-calc(16);
		text-transform: uppercase;
		margin-bottom: 0px;
		height: rem-calc(46);
		line-height: rem-calc(46);
		padding: 0px;
		@include breakpoint(large){
			max-width: rem-calc(220);
		}
	}
	.EditingFormErrorLabel{
		margin-bottom: rem-calc(10);
		display: block;	
	}
}
select{
	-webkit-appearance:none;
    margin-bottom:0px;
}
.required,.EditingFormErrorLabel, .ErrorLabel{
	color: $secondary-color;
}

.contact-info{
	padding-top:rem-calc(50);
	&.contact-info-address{
		padding-top:rem-calc(30);
	}	
	h6{
		margin: 0px;
	}
	a{
		color: $secondary-color;
	}
	.button{
		width: 100%;
		max-width: rem-calc(360);
		font-weight: 500;
		text-transform: uppercase;
		height: rem-calc(46);
		line-height: rem-calc(46);
		padding: 0px;
		margin-top: rem-calc(20);
	}
	.social-icons{
		margin-bottom: rem-calc(20);
		a{
			margin-right: rem-calc(15);
			font-size: rem-calc(20);
			color: $primary-color;
		}
	}

}
.thank-you{
	margin-bottom: rem-calc(30);
}
iframe.contact-map {
	width: 100%;
	height: rem-calc(450);
	border: none;
    margin-bottom:rem-calc(20);
    @include breakpoint(medium down){
        margin-top:rem-calc(20);
    }
}
.radio-inputs{
    .radio-buttons{
        display:inline-block;
        width:100%;
        padding:rem-calc(8 0);
        .radio-field{
            display:inline-block;
            margin-right:rem-calc(10);
            input{
                margin:0px;
                position:relative;
                top:1px;
            }
        }
    }
}
.message-error{
    padding:rem-calc(10);
}
/**RMA form*/
.rma-product{
    h4{
        background:$body-text;
        font-size:rem-calc(20);
        padding:rem-calc(5 10);
        color:$white;
        margin-top:rem-calc(15);
    }
}


