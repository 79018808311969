.about-page{
	.col-space-right{
		p{
			margin:rem-calc(20 0 50);
			@include breakpoint(large){
				margin:rem-calc(5 0 40);
			}	
		}		
	}
}
.sections-about{
	padding: rem-calc(10 0 60);
}

.about-image-blk{
	margin: rem-calc(40 0);
}
ul.fa-icons{
	list-style: none;
	margin:rem-calc(0 0 20);
	padding: 0px;
	display: inline-block;
	width: 100%;
	font-size: rem-calc(15);
	li{
		padding: rem-calc(0 0 30 40);
		position: relative;
		i{
			position: absolute;
			left: 0px;
			top: 0px;
			font-size: rem-calc(25);
			color: $secondary-color;
		}
		span{
			font-weight: 900;
			color: $black;
		}
	}
	&.black{
		font-weight: 900;
		color: $black;
	} 
	&.two-cols{
		li{
			height: rem-calc(60);
			@include breakpoint(medium){
				width: 50%;
				float: left;			
			}			
		}
	}
}


.gray-box-full{
	background-color:#eeeeee;
	padding: rem-calc(20 0);
	> .row > .column.large-6{
		padding-top: rem-calc(50);
		padding-bottom: rem-calc(40);
	}
	ul.fa-icons{
		margin-bottom: 0px;
		margin-top:rem-calc(30);
	}
	.clients{
		padding-top: rem-calc(35);
		text-align: center;
		@include breakpoint(medium){
			text-align: left;
		}
		img{
			margin-bottom: rem-calc(15);
		}
	}
}
.bordered-bottom{
	border-bottom: 1px solid #cccccc;
	@include breakpoint(large){
		border-bottom: none;
	}	
}
.blue-pattern-box-full{
	background-image: url('../images/about-blue-pattern.jpg');
	padding: rem-calc(40 0 60);
	color: $white;
	@include breakpoint(large){
		padding: rem-calc(60 0);
	}
	h3{
		color: $white;
	}
	p{
		font-size: rem-calc(20);
		@include breakpoint(large){
			font-size: rem-calc(24);
		}
	}
	span{
		font-weight: 900;
	}
}
.mission-conenct{
	.column.large-6{
		padding-top: rem-calc(50);
		padding-bottom: rem-calc(40);
	}
	address{
		font-style: normal;
		margin-bottom: rem-calc(30);
	}
	.ph-numbers{
		margin-bottom: rem-calc(20);
		a{
			color: $secondary-color;
		}
	}	
	.social-icons{
		margin-bottom: rem-calc(40);
		a{
			margin-right: rem-calc(15);
			font-size: rem-calc(20);
			color: $primary-color;
		}
	}
	.col-space-right{
		p{
			margin:rem-calc(0 0 20);			
		}		
	}
}