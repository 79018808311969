$inline-label-breakpoint: large;

.fieldset {
	// max-width: rem-calc(650);
	margin: rem-calc(18) auto;
	.title {
		margin-bottom: rem-calc(10);
		font-weight: bold;
		font-family: $body-font-family;
	}
	.form-fields {
		margin: rem-calc(0 0 15);
		padding: rem-calc(0 0 15);
		border-bottom: 1px solid map-get($foundation-palette, divider);
	}
}
.buttons {
    margin-top: rem-calc(10);    
}
.button{
    text-transform:uppercase;
    font-weight:500;
    font-family:$body-font-family;
}
.button.large {    
    border:none;
    text-transform: uppercase;
    padding: rem-calc(17 45);        
    font-size:rem-calc(16);     
}

form .buttons {
    margin-top: rem-calc(10);
	/*.contactus & {
		margin-top: rem-calc(10);
	}*/
	.catalogrequest & {
		margin-top: rem-calc(10);
	}
}


.inline-label {
	margin: 0;
	label {
		@include breakpoint($inline-label-breakpoint) {
			padding-right: 10px;
			width: 150px;
			text-align: right;
			display: inline-block;
		}
	}

	input,
	select,
	textarea {
		@include breakpoint($inline-label-breakpoint) {
			display: inline-block;
			width: 300px;
		}
	}

	.required {
		@include breakpoint($inline-label-breakpoint) {
			position: absolute;
	    	left: rem-calc(141);
	    	top: rem-calc(7);
	  }
	}

	span.field-validation-error {
		@include breakpoint($inline-label-breakpoint) {
			padding-left: 155px;
			margin-bottom: rem-calc(5);
		}
	}
}



// input::placeholder, textarea::placeholder { 
// 	color: #c7c7c7;
// 	opacity: 1;
// }
// input::-ms-input-placeholder,textarea::-ms-input-placeholder{
// 	color: #c7c7c7;
// 	opacity: 0.5;
// }
  
input, textarea, select{
	&::-webkit-input-placeholder { /* Chrome */
		color:#c7c7c7;
	  }	  
}
input, textarea, select{
	  &:-ms-input-placeholder { /* IE 10+ */
		color:#c7c7c7;
		opacity:1;
	  }	
}
input, textarea, select{	
	  &::-moz-placeholder { /* Firefox 19+ */
		color:#c7c7c7;
		opacity: 1;
	  }
}
input, textarea, select{
	  &:-moz-placeholder { /* Firefox 4 - 18 */
		color:#c7c7c7;
		opacity: 1;
	  }
}



// custom
input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"]{
	margin-bottom:0;
}
.message-error{
	padding:rem-calc(15 15 0 15);
	.validation-summary-errors{
		color: $alert-color;
		ul{
			margin:rem-calc(0 0 0 17);
		}
	}
}
.form-fields{
	@include breakpoint(medium down){
		padding-left:0;
		padding-right:0;
	}
	.inputs{
		padding-bottom:rem-calc(10);
		label{
			display: inline-block;
		}
		span{
			&.required{
				color: $alert-color;
			}
		}
		.input-validation-error{
			border:1px solid $alert-color;
		}
		.field-validation-error{
			font-size: rem-calc(14);
			color: $alert-color;
		}
		&.input-nospace{
			padding-bottom: 0;
		}
		input[type="checkbox"]{
			margin-top:0;
			margin-bottom: 0;
            position:relative;
            top:2px;
		}
        textarea{
            margin-bottom:0px;
        }
        .input-others{
            margin-top:10px;
        } 
        .k-datepicker{
            display:block;
            input.form-control.text-box{
                height:rem-calc(32);
            }
        }
	}
}
.error{
	color: $alert-color;
}
.spMinChar{
	font-size: 12px;
	margin: 5px 10px 0px 0px;
	display: block;
	text-align: right;
	color: $alert-color;
}
