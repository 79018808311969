.ordersku {
    .ui-autocomplete {
        border-radius: 0;
        border-top: 0;
        max-height: rem-calc(250);
        overflow-y: auto;
        @include breakpoint(small down) {
            max-width: rem-calc(250);
        }

        @include breakpoint(medium up) {
            max-width: 80%;
            max-height: rem-calc(400);
            overflow-y: auto;
        }

        .ui-menu-item {
            font-size: rem-calc(13);
            border-radius: 0;
            margin-bottom: rem-calc(8);
            a {
                border-radius: 0;
            }
        }
    }
    .ui-autocomplete-loading {
        background: url("../images/preloader.gif") no-repeat right center $white;
        background-size: rem-calc(50);
    }
}

.order-sku-page {
    padding: rem-calc(20 0);
    .obs-search-wrap {
        background: $medium-gray;
        padding: rem-calc(20 20 30 20);
        border: 1px solid #eee;
        margin: rem-calc(15 0);
        input[type="text"] {
            border-radius: 0px;
            border-color: #333333;
            height: 40px;
            @include breakpoint(large only) {
                max-width: 72%;
                float: left;
            }

            @include breakpoint(xlarge up) {
                max-width: 79%;
                margin-right: rem-calc(10);
                float: left;
                margin-bottom: 0;
            }

            @include breakpoint(medium down) {
                max-width: rem-calc(405);
            }
        }
        .button {
            //line-height: rem-calc(30);
            @include breakpoint(large up) {
              //  float: right;
            }

            @include breakpoint(medium down) {
                max-width: rem-calc(405);
                width: 100%;
                margin-top: 10px;
                line-height: normal;
            }
        }
        &:after {
            display: table;
            content: '';
            width: 100%;
        }
        @include breakpoint(medium down) {
            margin-top: 0;
            h6 {
                font-size: 1rem;
            }
        }
    }
    .ordersku-content {
        border-spacing: 0;
        border-collapse: collapse;
        font-size: rem-calc(14);
        & > tbody {
            & > tr {
                & > th {
                    background: $primary-color;
                    color: $white;
                    text-align: left;
                    @include breakpoint(large up) {
                        white-space: nowrap;
                    }

                    input[type="checkbox"] {
                        margin-bottom: 0;
                    }
                }
                & > td {
                    input[type="checkbox"] {
                        margin-bottom: 0;
                    }
                    &:nth-of-type(2) {
                        @include breakpoint(large up) {
                            white-space: nowrap;
                        }
                    }
                    .qty-input {
                        max-width: rem-calc(80);
                        text-align: center;
                        margin-bottom: 0;
                    }
                }
                &:nth-child(even){
                    background:$medium-gray;
                }
            }

        }
        @include breakpoint(medium down) {
            &,thead,tbody,tr,td,th {
                display: block;
                width: 100%;
            }
            & > tbody {
                & > tr {
                    & > th {
                        &:not(:first-child) {
                            display: none;
                        }
                        &:nth-of-type(1) {
                            &:before {
                                content: 'Bulk Select:';
                            }
                        }
                    }
                    & > td, & > th {
                        position: relative;
                        padding-left: rem-calc(115);
                        min-height: rem-calc(30);
                        height: auto;
                        &:before {
                            position: absolute;
                            left: rem-calc(10);
                            top: rem-calc(8);
                            font-weight: 500;
                            //font-family: $font-medium;

                        }
                    }
                    & > td {
                        &:nth-of-type(1) {
                            &:before {
                                content: 'Select:';
                            }
                        }
                        &:nth-of-type(2) {
                            &:before {
                                content: 'SKU:';
                            }
                        }
                        &:nth-of-type(3) {
                            &:before {
                                content: 'Product name:';
                            }
                        }
                        &:nth-of-type(4) {
                            &:before {
                                content: 'Availability:';
                            }
                        }
                        &:nth-of-type(5) {
                            &:before {
                                content: 'Unit price:';
                            }
                        }
                        &:nth-of-type(6) {
                            &:before {
                                content: 'Quantity:';
                            }
                        }
                        &:nth-of-type(7) {
                            &:before {
                                content: 'Total price:';
                            }
                        }
                    }
                }
            }
        }
    }
    @include breakpoint(small down) {
        .button {
            width: 100%;
        }
    }
}