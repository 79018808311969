$block: '.checkout-page';
$cart-breakpoint: 800;
#{$block} {

	.checkout-disclosure {
		font-size: rem-calc(14);
		font-style: italic;
    	padding: 10px 0;
	}

	.chkbox-wrap {
		line-height: 1;


		label {
			display: inline;
		}
	}
  #confirm-order-buttons-container {
      text-align: right;
  }
  table.cart {
    margin: 0;

    td.quantity {
    	text-align: center;
    }
  }
  .page-title {
      margin: rem-calc(10 0);
  }
  .validation-summary-errors {
      color: red;
      font-size: rem-calc(12);
      ul {
          margin-left: rem-calc(15);
          li {
              list-style: none;
          }
      }
  }
ul.checkout-ul{
		margin: 0;
		list-style: none;
		@extend .clearfix;

		li.billing-shipping-wrap,li.shipping-shop-confidence-wrap{
			margin: 0;
			border: none;
			> ul{
				margin: 0;
				@include breakpoint(large){
					display: flex;
				}
			}
		}
			> li{
				margin-bottom: rem-calc(20);
				border: 1px solid $primary-color;
				&.place-order-button{
					border:none;
					.buttons {
						text-align: right;
						.add-to-cart-button {
							background: map-get($foundation-palette, alert);
							text-align: center;
							font-weight: bold;
							text-transform: uppercase;
							color: #fff;
							display: inline-block;
							appearance: none;
							border: 0;
							width: auto;
							line-height: rem-calc(35);
							font-family: $body-font-family;
							transition: background 300ms ease;
							// padding: rem-calc(5 25);
							&:hover {
								cursor: pointer;
								background: darken(map-get($foundation-palette, alert), 8%);
							}
						}
					}
				}

				&.no-border {
					border: 0;
					margin: 0;
				}

				@include breakpoint(large) {
					float: left;
					width: 100%;

					&#opc-payment_method,
					&#opc-payment_info {
						width: 49%;
						float: left;
					}

					&#opc-payment_info, {
						margin-left: 2%;
					}
				}

				.billing-shipping-block,.shipping-shop-confidence-block{
					margin-bottom: rem-calc(20);
					border:1px solid $primary-color;
					@include breakpoint(large){
						width: 50%;
						.shipping-form{
							margin-top: rem-calc(30);
						}
					}
				}
				.shop-confidence-block-faq{
					.step.a-item{
						padding-top: 5px;
						padding-bottom: 5px;
					}
					ul.faq-list{
						margin: 0px;
						list-style: none;
						padding: rem-calc(3 0);
						li{
							margin-bottom: rem-calc(4);
							padding: rem-calc(5 0);
							font-size: rem-calc(14);
							font-weight: 500;
							.faq-answer{
								display: none;
							}
						}						
					}
				}

				.title{
					padding: rem-calc(0 0 0 15);
				}

				.step-title{
					background: $primary-color;
					color: $white;
					padding: rem-calc(5 10);
					font-size: rem-calc(16);
					margin:0px;
					font-weight: 500;
                    text-transform:capitalize;
				}
				.step.a-item {
				    padding: rem-calc(15);

						&#checkout-step-shipping-method {
							padding-top: rem-calc(3);
							padding-bottom: rem-calc(5);
						}

						&#checkout-step-payment-info {
							padding: 0;

							.buttons {
								padding: 0 10px;

								@include breakpoint(medium) {
									padding: 0;
								}
							}
						}

						&#checkout-step-payment-method {
							padding: rem-calc(5);
						}

				    .section{
				    	> label{
				    		font-weight: 500;
				    		margin-bottom: rem-calc(10);
				    		line-height: normal;
				    	}
				    }
				    p.selector{
				    	input{
				    		margin-bottom: 0;
				    		position: relative;
				    	}
				    	label{
				    		display: inline-block;
				    		line-height: normal;
				    	}
				    }
				    p{
				    	margin-bottom: rem-calc(5);
				    }
				    .back-link{
				    	float: left;
				    	margin-right: rem-calc(10);
				    }
				    div.single-pickup-point{
				    	margin-bottom: rem-calc(20);
				    	font-size: rem-calc(15);
				    }
				}
				ul.method-list{
					margin: 0px;
					list-style: none;
					@include breakpoint(medium) {
						padding: rem-calc(3 0);
					}
					li{
			    	padding: rem-calc(5 0);
				    border-top:2px dotted #eee;
				    &:first-child{
				    	border-top: none;
				    }
				    .method-name{
				    	input{
				    		margin-bottom: 0px;
						    position: relative;
						    top: 1px;
				    	}
				    	label{
					    	font-weight: 500;
					    	margin: rem-calc(0 2);
					    	line-height: normal;
					    	display: inline;
				    	}
				    }
					}
				}
				&.payment_method{
					ul.method-list{
						li{
						    display: block;
						    margin-right: 0px;
						    padding: rem-calc(10);

						    @include breakpoint(medium) {
						    	padding: rem-calc(10);
						    	border-top: none;
						    	.payment-logo {
								    text-align: center;
									}
						    }
						    @include breakpoint(medium) {
				    			display: inline-block;
							}
							.payment-details {
							    margin-right: 10px;
							    position: relative;
							    top: 4px;
							}
						}
				}
			}
			.billing-info-wrap, .shipping-info-wrap {
			    margin: rem-calc(10 0 20);
			    background: #eee;
			    width: 49%;
			    float: left;
			    .title{
			    	background: #1f435a;
				    color: #fff;
				    padding: rem-calc(5 15);
			    }
			    .info-content {
				    padding:rem-calc(10 15 15);
				    ul{
				    	list-style: none;
				    	margin:0px;
				    	li.title{
				    		background: none;
				    		color: #0a0a0a;
				    		padding: 0px;
				    		strong{
				    			font-weight: 500;
				    		}
				    	}
				    }
				}
				@include breakpoint(small only) {
					float: none;
					margin:rem-calc(0 0 20 0);
				}
			}
			.shipping-info-wrap{
				float: right;
			}

			.cart-options {
			    padding-bottom: 10px;
			    font-weight: 500;
			}


			.please-wait {
			    display: inline-block;
			    padding: rem-calc(10);
			    position: relative;
			    top: -7px;
			}
			.section.payment-info {
			    margin-bottom: rem-calc(10);
				.info
				{
					tbody{
				    	border:none;
				    	td{
				    		display: block;
				    		.label-wrapper{
				    			display: inline;
				    		}
				    		span.required{
								color: map-get($foundation-palette, alert);
							}
				    		input{
				    			margin-bottom: rem-calc(10);
				    			display: inline;
				    		}
				    		select{
			    				margin-bottom: rem-calc(10);
			    				width: auto;
				    		}
				    		@include breakpoint(small only) {
				    			display: block;
					    			select:first-child{
						    			margin-bottom: rem-calc(10);
						    		}
								}
								@include breakpoint(medium){
									display: table-cell;
									padding: rem-calc(7 10);
									&:first-child{
										width: rem-calc(150);
									}
									select, input{
						    			margin-bottom: rem-calc(0);
						    		}
								}
					    	}
				    }
					.row.sep-bottom {
						padding-bottom: rem-calc(20);
						margin-bottom: rem-calc(20);
						border-bottom: 1px solid #e9e8e8;
					}
					label{
			    			display: inline;
							&.form-hint {
								font-size: rem-calc(11);
								font-style: italic;
							}
			    		}

				}
			    table{
			    	border: none;
			    	border-collapse: collapse;
			    	margin-bottom: 0px;
			    	td{
			    		label{
			    			display: inline-block;
			    		}
			    		input, select{
			    			margin-bottom: 0px;
			    		}
			    		select{
			    			width: 100px;
			    		}
			    		@include breakpoint(small only) {
			    			display: block;
			    			select:first-child{
				    			margin-bottom: 10px;
				    		}
						}
			    	}
			    }

			}
			@include breakpoint(small only) {
    			.button{
    				font-size: rem-calc(12);
    			}
			}
		}
	}
	.buttons{
		margin-top: rem-calc(5);
		text-align: center;


		.button{
			font-size:rem-calc(12);
			padding: 0;
			width: 100%;
			@include breakpoint(small){
				width: auto;
				padding: rem-calc(0 25);
			}
			@include breakpoint(medium){
				padding: rem-calc(0 35);
				font-size: rem-calc(16);
			}
		}
	}
}
.shoppingcart {
	.cart-top-links{
		margin-bottom: rem-calc(20);
		margin-top: rem-calc(20);
		@include breakpoint(medium){
			float: left;
		}
		a{
			text-align: center;
			display: inline-block;
			margin-right: rem-calc(15);
			font-size: rem-calc(14);
			color: $black;
			span{
				display: block;
				padding-bottom: rem-calc(5);
			}
		}
	}
	#printableArea{
		clear: both;
	}
	.common-buttons{
		display: none;
		margin: rem-calc(15 0);
		@include breakpoint(small down) {
			display: block;
			margin-top: rem-calc(15);
			margin-bottom: rem-calc(5);
			button {
				display: block;
				width: 100%;
				margin: 0;
			}
		}
		&.button-top{
			@include breakpoint(medium){
				float: right;
				.button{
					margin:rem-calc(20 0 0 0);
				}
			}
		}
	}
	.checkout-buttons, .common-buttons{
		.primary-red-button{
			float: right;
			width: 100%;
			@include breakpoint(medium){
				width: auto;
			}
		}
	}
	.checkout-buttons{
		display: inline-block;
		width: 100%;
	}
}
.mobile-shopping-cart {
	@include breakpoint($cart-breakpoint) {
		display: none;
	}

	.product-name {
		font-weight: 500;
		display: inline-block;
		line-height: 1.4;
    color: map-get($foundation-palette, active);
	}
	.product-unit-price {
		font-weight: bold;
	}

	.delete-button {
		color: map-get($foundation-palette, alert);
    line-height: rem-calc(30);
	}

	.cart-item {
		border-bottom: 1px solid map-get($foundation-palette, divider);
		padding: rem-calc(15 0);

		.button {
			vertical-align: top;
			margin: 0 0 0 rem-calc(10);
		}
	}

	.product-top {
		display: flex;
		margin-bottom: rem-calc(10);
	}

	.product-picture,
	.product-info {
		float: left;
	}
	.product-picture {
		width: 80px;
		min-width: 80px;
		margin-right: 20px;
	}

	.quantity {
		input {
			width: rem-calc(65);
	    height: 30px;
	    margin: 0;
	    vertical-align: top;
			display: inline-block;
			text-align: center;

			@include breakpoint(medium) {
				height: 39px;
			}
		}

		.delete-button {
			color: map-get($foundation-palette, alert);
		}
	}
}



.table-wrapper {
    clear: both;
    table.cart {
        border-collapse: collapse;
        display: none;
        thead {
            background: $primary-color;
            color: $white;
            th {
                font-weight: 500;
                @include breakpoint($cart-breakpoint) {
                		padding: rem-calc(6 12);
					&.quantity
					{
						text-align: center;
					}
                		&.unit-price {
                			text-align: right;
                		}
                		&.subtotal {
											text-align: right;
                		}
                }

            }
        }
        tbody {
            border: none;

            td:first-child {
            	@include breakpoint($cart-breakpoint) {
            		vertical-align: middle;
            	}
            	a {
								transition: color 300ms ease;
								display: block;

								.email-a-friend-page & {
									width: 69px;
								}

								&:hover {
									text-decoration: underline;
								}
            	}
            }
        }

        @include breakpoint($cart-breakpoint) {
						display: table;
            tbody {
                tr {
                    padding: rem-calc(0);
                    margin-bottom: rem-calc(0);
                    &:nth-child(even){
						background:none;
						border-top: 1px solid $medium-gray;
						border-bottom: 1px solid $medium-gray;
                    }
                }
                td {
                		padding: rem-calc(10 14);
                		vertical-align: top;
                    display: table-cell;
                    &.quantity
                		{
                			text-align: center;
						}
						&.unit-price{
							text-align: right;
						}
                		&.subtotal {
											text-align: right;
                		}
                    label {
                        display: block;
                    }
                }
            }
        }
    }
}

@include breakpoint($cart-breakpoint) {
    .remove-from-cart {
        text-align: center;
    }
}

.total-info {
    text-align: right;
}

table.cart-total {
    border-collapse: collapse;
    display: inline-block;
    width: auto;
    margin-top: rem-calc(15);

    @include breakpoint($cart-breakpoint) {
			margin-top: rem-calc(0);
    }
    // border: 1px solid map-get($foundation-palette, divider);

		#checkout-step-confirm-order & {
			margin: rem-calc(6 0 0 0);
		}

    tbody {
        border: none;
        background: none;
    }
    tr {
        &:nth-child(even) {
            background: none;
        }
        &:last-child {
            td {
                border-bottom: 0;
            }
        }
        .selected-shipping-method {
        	display: none;

        	@include breakpoint($cart-breakpoint) {
        		display: block;
        		font-size: rem-calc(14);
        	}
        }
        td {
        	padding: rem-calc(4);
      		font-size: rem-calc(14);
      		@include breakpoint(small) {
      			font-size: rem-calc(16);
      		}
          @include breakpoint(large) {
          	padding: rem-calc(4 10);

          }
          // border-bottom: 1px solid map-get($foundation-palette, divider);
          &.cart-total-left {
              label {
                  font-weight: 600;
              }
          }
        }

        &.order-subtotal {
          .value-summary {
          	font-weight: bold;
          }
        }
    }
}
.cart-options {
    .checkout-attributes {
        margin: rem-calc(15 0);
        label {
            display: inline-block;
            font-weight: 500;
        }
        select {
            margin-bottom: 0px;
        }
    }
    .selected-checkout-attributes {
        font-weight: 500;
        margin-bottom: 10px;
    }
}

.shopping-cart-page {
	h1 {
		margin-top: 0;
	}

	.button {
		padding:rem-calc(12 16);
		&.checkout-button{
			float: right;
			@include breakpoint(small only) {
				width: 100%;
			}
		}
		@include breakpoint(small only) {
			font-size: rem-calc(13);

		}
	}

	.product-picture {
			img {

				@include breakpoint($cart-breakpoint) {
					// max-width: none;
					width: auto;
				}
			}
		}
    .qty-input {
        width: rem-calc(65);
        text-align: center;
        @include breakpoint($cart-breakpoint) {
            margin: 0 auto;
            min-width: rem-calc(65);

        }
        + a{
        	margin: rem-calc(0 10);
        	line-height: rem-calc(30);
        	color: $black;
  			transition: color 300ms ease;
			
			&:hover {
				//color: darken(map-get($foundation-palette, active), 15%);
				text-decoration: underline;
			}
        }
    }
    .delete-button {
    	color: map-get($foundation-palette, alert);

    	&:hover {
    		color: darken(map-get($foundation-palette, alert), 10%);
    		text-decoration: underline;
    	}
    }
    .cart-footer {
        .deals {
            .coupon-box,
            .giftcard-box {
                margin-bottom: 20px;
                border: 1px solid $primary-color;
                .coupon-code {
                    padding: rem-calc(15)
                }
            }
        }
        .shipping {
            margin: rem-calc(15 0);
            .estimate-shipping {
                border: 1px solid $primary-color;
            }
            .shipping-options {
                padding: rem-calc(15 15 0 15);
            }
        }
        .title {
            background: $primary-color;
            color: $white;
            padding: rem-calc(5 10);
            font-size: rem-calc(16);
            margin: 0px;
            font-weight: 500;
        }
        .hint {
            padding: rem-calc(15 0 0 15);
        }
    }
}

.blue-box-cart {
    margin-bottom: rem-calc(20);
    border: 1px solid $primary-color;
    .step-title {
        background: $primary-color;
        color: $white;
        padding: rem-calc(5 10);
        font-size: rem-calc(16);
        margin: 0px;
        font-weight: 500;
    }
    .step.a-item {
        padding: rem-calc(10);
    }
    .character-count {
        font-size: rem-calc(14);
        text-align: right;
    }
}

#opc-fcc-required {
    clear: left;
}

/*Styles from one page checkout*/
.red{
   color: red;
}
.blue{
   color: blue;
}
.smallWhite{
	color:white;
	font-size:16px;
	left: 50%;
}
.green{
   color:green;
}
.divLoad {
    margin: 0px;
    padding: 0px;
    position: fixed;
    right: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #666666;
    z-index: 30001;
    opacity: .9;
    filter: alpha(opacity=90);
    display:none;
}
.paraLoad {
    position: absolute;
    top: 40%;
    text-align: center;
    left: 35%;
    width: 400px;
    color: White;
    @include breakpoint(small only){
    	width: 100%;
    	left: 0;
    }
}

/* Tooltip container */
.tooltip-asv {
    position: relative;
    display: inline-block;
    font-size: rem-calc(12);
    //border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip-asv .tooltiptext {
    visibility: hidden;
    width: rem-calc(240);
    background-color: #2c3139;
    color: #fff;
    padding: rem-calc(5);
 	font-size: rem-calc(12);
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-asv:hover .tooltiptext {
    visibility: visible;
}

.tooltip-asv .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 20%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #2c3139 transparent;
}
.primary-red-button {
	background: map-get($foundation-palette, alert);
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	color: #fff;
	display: inline-block;
	appearance: none;
	border: 0;
	width: auto;
	line-height: rem-calc(35);
	font-family: $body-font-family;
	transition: background 300ms ease;
	// padding: rem-calc(5 25);
	&:hover {
		cursor: pointer;
		background: darken(map-get($foundation-palette, alert), 8%);
	}
}

// #checkout-confirm-order-load {
// 	.table-wrapper table.cart tbody td.subtotal {
// 		// text-align: center;
// 	}
// }
.showMessage {
	max-width: 600px;
	width: 100%;
	text-align: center;
	p{
		margin:rem-calc(20  0);
	}
	.button{
		margin:rem-calc(10);
	}
}
/*FAQ fancybox*/
.faq-answer{
	h1{
		padding:rem-calc(5 15);
		background: $primary-color;
		color:$white;
		margin:0;
		border-radius: rem-calc(5);
	}
	.page-body{
		padding: rem-calc(20 15);
		font-size: rem-calc(16);
	}
}
