.search-result-page {
    .search-dialog {
        position: relative;
        padding: 0;
        border: none;
        width: auto;
        margin-top: rem-calc(20);
        display: block;
        max-width: rem-calc(680);
        margin-bottom: rem-calc(30);
        @include breakpoint(large) {
            margin-bottom: rem-calc(60);
        }
        .editing-form-label-cell {
            display: none;
        }
        input {
            padding-right: rem-calc(60);
            font-size: rem-calc(18);
            //color: $secondary-color;
            box-shadow: none;
            margin-bottom: 0;
            border: 2px solid #ddd;
            font-weight: 500;
            height: rem-calc(60);
            padding-left: rem-calc(20);
            &.btn {
                height: rem-calc(60);
                padding: 0;
                position: absolute;
                right: 1px;
                top: 0px;
                color: $white;
                border: none;
                width: rem-calc(60);
                text-indent: -999px;
                background: $primary-color url(../images/search-icon-large.png) no-repeat center center;
            }
        }
    }
}

.search-filter {
    @include breakpoint(large) {
        margin-right: rem-calc(30);
    }
    .filters-wrap {
        border-bottom: 1px solid #cccccc;
        padding: rem-calc(5 10 0);
        margin: 0px -10px;
        @include breakpoint(medium) {
            border-bottom: none;
            padding: 0px;
            margin: 0px;
        }
    }
    label {
        font-size: rem-calc(14);
        margin-bottom: rem-calc(10);
        font-weight: 900;
        color: $black;
        display: inline-block;
        margin-right: rem-calc(15);
        i {
            margin-right: rem-calc(5);
        }
    }
    .change-filter {
        font-weight: 300;
        font-size: rem-calc(17);
        position: relative;
        top: 2px;
        @include breakpoint(medium) {
            display: none;
        }
        i {
            margin-left: rem-calc(5);
        }
    }
    .show-all {
        float: right;
        margin-top: 4px;
        color: $secondary-color;
        margin-right: 0px;
        @include breakpoint(medium) {
            display: none;
        }
    }
    .filter-options {
        display: none;
        background: #efefef;
        padding: rem-calc(0 20);
        margin: 0px -10px;
        @include breakpoint(medium) {
            display: block !important;
            background: none;
            padding: 0px;
            margin: 0px;
        }
    }
    table.ContentCheckBoxList {
        border-spacing: 0px;
        tbody {
            background: none;
        }
        tr {
            &:nth-child(even) {
                background: none;
            }
            &:last-child {
                td {
                    label {
                        border: none;
                    }
                }
            }
            td {
                padding: 0px;
                position: relative;
                input {
                    position: absolute;
                    top: 10px;
                    opacity: 0;
                    &:checked {
                        &+label {
                            background: #eee;
                            &:before {
                                color: $primary-color;
                                content: "\f14a";
                            }
                        }
                    }
                }
                label {
                    display: block;
                    padding: rem-calc(12 20 12 30);
                    margin: 0px;
                    font-size: rem-calc(16);
                    font-weight: 500;
                    &:before {
                        font-family: FontAwesome;
                        content: "\f096";
                        color: $dark-gray;
                        position: absolute;
                        left: 0px;
                    }
                    &.active {
                        &:before {
                            color: $primary-color;
                        }
                    }
                }
            }
        }
    }
}

.results-search {
    padding-bottom: rem-calc(20);
    .ContentLabel {
        position: relative;
        top: 10px;
    }
    .count {
        font-size: rem-calc(14);
        color: $secondary-color;
        font-weight: 500;
    }
    .search-list {
        padding: rem-calc(25 0);
        a {
            color: $secondary-color;
            font-size: rem-calc(18);
            font-weight: 500;
            &:hover {
                text-decoration: underline;
            }
        }
        span.link-location {
            display: block;
            color: #999999;
            font-size: rem-calc(14);
        }
        .description {
            font-size: rem-calc(15);
            color: #555555;
        }
    }
}

// .pager-wrap{
// 	text-align: center;
// 	border-top: 1px solid #bbbbbb;
// 	margin-top: rem-calc(50);
// }
// .pager{
// 	display: inline-block;	
// 	padding: rem-calc(50 0);	
// 	a, span{
// 	  color: #999999;
// 	  float: left;	  
// 	  min-width: rem-calc(20);
// 	  text-align: center;
// 	  padding: rem-calc(2 8);
// 	  margin: rem-calc(5);
// 	  font-size: rem-calc(14);
// 	  border:1px solid #bbbbbb;
// 	  &:hover{
// 	  	background: $primary-color;
// 	  	color: $white;
// 	  }
// 	}
// 	span{
// 		background: $primary-color;
// 		color: $white;
// 		border-color: $primary-color;
// 	}
// }
body.scroll-hidden{
    overflow: hidden;
}
.search-title h1{
    margin-bottom:0px;
}
a.filter-btn.button.primary.large {
    margin: rem-calc(0 10 0);
    i{
        margin-right: rem-calc(7);
    }
    @include breakpoint(large){
        display: none;
    }
}
.nextopia-search-page{
    padding:rem-calc(20 0);
}
.wrapper-bg{
    padding:rem-calc(10 20);
    background:$white;
}
.subnav.filtertab-left {
    border: 1px solid #dddddd;
    position: fixed;
    left: -400px;
    top: 0px;
    bottom: 0px;
    overflow-x: auto;
    width: rem-calc(280);
    background: $white;
    z-index: 1000;
    @include breakpoint(large){
        position: relative;
        left: auto !important;
        top: auto;
        bottom: auto;
        width: auto;
        z-index: 1;
    }
   
    .leftSidebar {
        h3 {
            background: $primary-color;
            color: $white;
            padding: 0px 15px;
            line-height: rem-calc(35);
            font-size: rem-calc(16);
            margin-bottom: 0px;
            font-weight:500;
            text-transform:capitalize;
        }        
        .nxt-remove-item {
            padding: 2px 11px;
            font-size: .9em;
        }
        .nxt-red-x {
            margin-left: 0;
            margin-right: 7px;
        }
        .yourselection {
            padding: 10px 0px;
        }
        .refine-row {
            .refinement {
                position: relative;
                a.filterresetlink {
                    position: absolute;
                    right: 10px;
                    top: 8px;
                    font-size: 12px;
                    color: #333;
                    font-weight: 600;
                    &:hover{
                        color:$primary-color;
                    }
                }
                h4.panelHeading {
                    background: $medium-gray;
                    padding: 10px 38px 10px 15px;
                    font-size: 13.4px;
                    line-height: 1;
                    font-weight: 600;
                    margin-bottom: 0px;
                    cursor: pointer;
                    &:hover{
                        color:$primary-color;
                        span.less:before,span.more:before{
                            color:$primary-color;
                        }
                    }
                    span{
                        position: relative;
                        display: inline-block;
                        padding-left:20px;
                        &.more,
                        &.less{
                            &:before{
                                position:absolute;
                                left: 0;
                                top: 0;
                                font-family: 'FontAwesome';
                                color: #888;
                            }
                        }
                        &.more{
                            &:before{
                                content: '\f067';
                            }
                        }
                        &.less{
                            &:before{
                                content: '\f068';
                            }
                        }
                    }
                }                
                &:not(.openselected){
                    ul.proListLeft.all_answers{
                        display: none;
                    }
                }
                ul.proListLeft.all_answers {
                    list-style: none;
                    padding: 0px;
                    margin: 10px 0px 10px 0px;
                    li {
                        padding: 0px 15px;
                        position: relative;
                        input {
                            display: inline-block;
                            position: absolute;
                            left: 15px;
                            margin-bottom: 0px;
                            top: 6px;
                        }
                        a {
                            display: block;
                            padding-left: 20px;
                            label{
                                cursor: pointer;
                            }
                            span {
                                font-size: 12px;
                                color: #999;
                            }
                        }
                    }
                }
            }
        }
    }
}